import request from '@/utils/request.js'
import config from "@/utils/config.js"

export function getBannerhttp(params){
  return request.get('/api/mp/index/getBanner',params);
}


export function getRecommendGoodshttp(params){
  return request.get('/api/mp/index/getRecommendGoods',params);
}

export function getBannerGoods(params){
  return request.post('/api/metaverse/goods/getBanner',params);
}

export function getGoodsListsBy(params){
  return request.post('/api/metaverse/goods/getGoodsListsBy',params);
}
// 购买
export function buyGoods(params){
  return request.post('/api/metaverse/goods/buyGoods',params);
}
// 是否支付
export function checkPay(params){
  return request.post(`/api/metaverse/common/checkPay`, params);
}

// 获取地址
export function getAddressList(params){
  return request.post(`/api/metaverse/address/getAddressList`, params);
}
// 添加地址
export function addAddress(params){
  return request.post(`/api/metaverse/address/addAddress`, params);
}
// 设置默认地址
export function setDefaultAddress(params){
  return request.post(`/api/metaverse/address/setDefaultAddress`, params);
}
// 删除地址
export function delAddress(params){
  return request.post(`/api/metaverse/address/delAddress`, params);
}
// 获取地址详情
export function getaddressInfo(params){
  return request.post(`/api/metaverse/address/getaddressInfo`, params);
}
// 获取商品详情
export function getGoodsDetails(params){
  return request.post(`/api/metaverse/goods/getGoodsDetails`, params);
}
// 订单确认
export function confirmBuyGoods(params){
  return request.post(`/api/metaverse/goods/confirmBuyGoods`, params);
}

// 获取订单列表
export function getOrdersList(params){
  return request.post(`/api/metaverse/orders/getOrdersList`, params);
}

// 获取订单详情
export function getOrderDetails(params){
  return request.post(`/api/metaverse/orders/getOrderDetails`, params);
}

// 退货申请
export function returnGoods(params){
  return request.post(`/api/metaverse/orders/returnGoods`, params);
}
// 七牛
export function getQiniuToken(params){
  return request.post(`/api/web/common/getQiniuToken`, params);
}
// 填写快递单号获取地址i
export function returnGoodsAddExpressNumber(params){
  return request.post(`/api/metaverse/orders/returnGoodsAddExpressNumber`, params);
}
// 七牛上传图片
export function qnRequest(data){
  return request({
    url:  config.qnuploadurl,
    method: 'post',
    data,
  });
}
// 删除订单
export function delOrder(params){
  return request.post(`/api/metaverse/orders/delOrder`, params);
}
// 确认收货
export function confirmReceipt(params){
  return request.post(`/api/metaverse/orders/confirmReceipt`, params);
}
// 订单立即付款
export function orderBuyGoods(params){
  return request.post(`/api/metaverse/orders/orderBuyGoods`, params);
}

// 登录
export function doLogin(params){
  return request.post(`/api/metaverse/doLogin`, params);
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order_box" },
    [
      _c("div", { staticClass: "backBtn" }, [
        _c(
          "div",
          {
            staticClass: "back",
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [
            _c("i", { staticClass: "el-icon-back" }),
            _vm._v("返回上一页\n      "),
          ]
        ),
      ]),
      _c("div", { staticClass: "address_view" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "address_view_list" },
          [
            _vm._l(_vm.addresslist, function (item, index) {
              return _c("addresItem", {
                key: index,
                attrs: {
                  dataobj: item,
                  isDefaultAddress: index == _vm.itemindex,
                },
                on: {
                  changeAddress: function ($event) {
                    return _vm.changeAddress(item, index)
                  },
                  changeDefault: _vm.changeDefault,
                },
              })
            }),
            _c(
              "div",
              {
                staticClass: "addres_item addaddres",
                on: { click: _vm.addaddress },
              },
              [
                _c("div", { staticClass: "add_icon" }),
                _c("span", [_vm._v("新增收获地址")]),
              ]
            ),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "goodslist_view" }, [
        _c("div", { staticClass: "goodslist_table" }, [
          _vm._m(1),
          _c("div", { staticClass: "goodslist_table_item" }, [
            _c("div", [
              _c("div", { staticClass: "goodsimg" }, [
                _c("img", { attrs: { src: _vm.goodsinfo.image, alt: "" } }),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.goodsinfo.title))]),
            ]),
            _c("div", [_c("span", [_vm._v(_vm._s(_vm.goodsinfo.skuValue))])]),
            _c("div", [
              _c("span", { staticClass: "Markerstext" }, [
                _vm._v("￥" + _vm._s(_vm.goodsinfo.price)),
              ]),
            ]),
            _c("div", [_c("span", [_vm._v(_vm._s(_vm.goodsinfo.number))])]),
            _c("div", [
              _c("span", { staticClass: "Markerstext" }, [
                _vm._v("￥" + _vm._s(_vm.goodsinfo.totalPrice)),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "goodslist_foot" }, [
          _c("div", [
            _c("p", [
              _vm._v("快递费用："),
              _c("span", { staticClass: "Markerstext" }, [
                _vm._v("￥" + _vm._s(_vm.goodsinfo.courierFee)),
              ]),
            ]),
            _c("p", [
              _vm._v("实付款："),
              _c("span", { staticClass: "Markerstext bigtext" }, [
                _vm._v("￥" + _vm._s(_vm.goodsinfo.disbursements)),
              ]),
            ]),
            _c("button", { on: { click: _vm.onPay } }, [_vm._v("购买")]),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "支付宝扫码", visible: _vm.codeShow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.codeShow = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c("img", { staticClass: "code", attrs: { src: _vm.codeImg } }),
          _c("div", { staticClass: "tips" }, [
            _vm._v("支付成功后，请关闭支付窗口"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "address_view_head" }, [
      _c("span", [_vm._v("收获地址")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "goodslist_table_header" }, [
      _c("div", [_vm._v("商品名称")]),
      _c("div", [_vm._v("商品属性")]),
      _c("div", [_vm._v("单价")]),
      _c("div", [_vm._v("数量")]),
      _c("div", [_vm._v("金额")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="notfound-container">
    当前页面走丢啦~~
    <router-link to="/"><el-button type="primary" block style="margin-top: .5rem">回首页</el-button></router-link>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>
.notfound-container{
  width: 100%;
  padding: 1rem;
  font-size: .2rem;
  text-align: center;
  color: #333;
}
</style>
<template>
  <div id="app">  
		<router-view></router-view>						
  </div>
</template>

<script>
import { getQiniuToken } from "@/utils/http.js"

export default {
  name: 'app',

  created(){
    this.getQiNiu()
  },
  methods:{
    async getQiNiu(){
      let res = await getQiniuToken()
      if(res.status == 0){
        localStorage.setItem('qnToken',res.data.qnToken)
      }
    },
  }
  
}
</script>

<style>
#app {
  min-height: 100vh;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d2533;
}
body{
	padding: 0;
	margin: 0;
}
* {
  padding: 0;
	margin: 0;
  box-sizing: border-box;
}
.zh-toast{
  height: auto !important;
  padding:.2rem .3rem  !important;
  font-size: .24rem !important;
  border-radius: .1rem !important;
  color: #fff !important;
  background: rgb(226 89 84) !important;
}
.zh-toast-pc{
  height: auto !important;
  padding: 15px !important;
  font-size: 16px !important;
} 
li{
  list-style-type:none
}
ul {
  margin: 0px;
  padding: 0px;
}
a{
  text-decoration:none
}
</style>

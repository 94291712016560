var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "purchase" },
    [
      _c(
        "div",
        { staticClass: "areaBoxs" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadData,
                  expression: "loadData",
                },
              ],
              staticClass: "landList",
              attrs: { "infinite-scroll-disabled": "disabled" },
            },
            _vm._l(_vm.landList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "items",
                  class: _vm.curInx == index ? "cur" : "",
                  on: {
                    click: function ($event) {
                      return _vm.onChooseArea(index, item.landId)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "bg",
                    attrs: { src: item.image, alt: "" },
                  }),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.landName)),
                  ]),
                  _c("div", { staticClass: "sellStatus" }, [
                    _vm._v("\n          已购：\n          "),
                    _c("div", { staticClass: "sold" }, [
                      _vm._v(_vm._s(item.buyCount)),
                    ]),
                    _vm._v("\n          /\n          "),
                    _c("div", { staticClass: "total" }, [
                      _vm._v(_vm._s(item.totalCount)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm.landList.length == 0
            ? _c("el-empty", {
                staticStyle: {
                  height: "100%",
                  "overflow-y": "auto",
                  "text-align": "center",
                },
                attrs: { description: "暂无数据" },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "details" }, [
        _c(
          "div",
          { staticClass: "tablist" },
          _vm._l(_vm.tablist, function (item, index) {
            return _c(
              "div",
              {
                key: "menpai" + index,
                staticClass: "items",
                class: _vm.curNavInx == index ? "cur" : "",
                on: {
                  click: function ($event) {
                    return _vm.onTab(index)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(item) + "\n      ")]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.loadData,
                expression: "loadData",
              },
            ],
            staticClass: "landLists",
            attrs: { "infinite-scroll-disabled": "disabled" },
          },
          [
            _vm._l(_vm.doorplateList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.doorplateId,
                  staticClass: "items",
                  class: [
                    _vm.curDoorInx === index ? "cur" : "",
                    item.memberId ? "isSold" : "",
                  ],
                  on: {
                    click: function ($event) {
                      !item.memberId
                        ? _vm.onChoseDoorplate(index, item)
                        : _vm.onPrompt()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "ft" }, [
                    _c("div", { staticClass: "numberPlate" }, [
                      _vm._v(_vm._s(item.doorplateName)),
                    ]),
                    _c("div", { staticClass: "areaInfo" }, [
                      _vm._v("占地面积：" + _vm._s(item.covered) + "㎡"),
                    ]),
                  ]),
                  !item.memberId
                    ? _c("div", { staticClass: "rt" }, [
                        _c("div", { staticClass: "price" }, [
                          _vm._v("\n            ￥\n            "),
                          _c("div", { staticClass: "num" }, [
                            _vm._v(_vm._s(item.price)),
                          ]),
                        ]),
                      ])
                    : _c("div", { staticClass: "rt" }, [
                        _c("div", { staticClass: "imgs" }, [
                          _c("img", { attrs: { src: item.avator, alt: "" } }),
                        ]),
                        _c("div", { staticClass: "status" }, [
                          _vm._v("已售出"),
                        ]),
                      ]),
                ]
              )
            }),
            _vm.doorplateList.length == 0
              ? _c("el-empty", {
                  staticStyle: {
                    height: "100%",
                    "overflow-y": "auto",
                    "text-align": "center",
                  },
                  attrs: { description: "暂无数据" },
                })
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "buyBox" }, [
        _c("div", { staticClass: "priceInfo" }, [
          _vm._v("\n      价格：\n      "),
          _c("div", { staticClass: "price" }, [
            _vm._v("\n        ￥\n        "),
            _c("div", { staticClass: "num" }, [
              _vm._v(_vm._s("" + (_vm.doorplateId ? _vm.amount : "0"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btns" }, [
          _c(
            "div",
            {
              staticClass: "viewGround",
              class: !_vm.doorplateId ? "disable" : "",
              on: { click: _vm.onLook },
            },
            [_vm._v("查看地面")]
          ),
          _c(
            "div",
            {
              staticClass: "buyLand",
              class: !_vm.doorplateId ? "disable" : "",
              on: {
                click: function ($event) {
                  return _vm.onPay()
                },
              },
            },
            [_vm._v("\n        购买土地\n      ")]
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "支付宝扫码", visible: _vm.codeShow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.codeShow = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c("img", {
            staticClass: "code",
            attrs: { src: _vm.codeImg, alt: "" },
          }),
          _c("div", { staticClass: "tips" }, [
            _vm._v("支付成功后，请关闭支付窗口"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
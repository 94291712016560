<template >
  <div class="orderinfo_box">

    <div class="orderinfo_status">
      <div class="orderinfo_status_icon"></div>
      <p class="orderinfo_status_text">订单状态：{{ statustext[orderStatus] }}</p>
      <p>订单编号：{{ goodsinfo.orderSn }}</p>
      <p>收货地址：{{ goodsinfo.address }}</p>
    </div>

    <div class="orderinfo_list_view">
      <div class="orderinfo_list_number">韵达快递 运单号:432691873134624</div>

      <div class="orderinfo_list_table tbhead">
        <div>商品名称</div>
        <div>商品属性</div>
        <div>单价</div>
        <div>数量</div>
        <div>金额</div>
      </div>

      <div class="orderinfo_list_table">
        <div>
          <div class="goodimg">
            <img :src="goodsinfo.image">
          </div>
          <div class="goodimgtext">
            <p>{{ goodsinfo.title }}</p>
          </div>
        </div>
        <div><span>{{ goodsinfo.skuValue }}</span></div>
        <div><span class="redtext">￥{{ goodsinfo.price }}</span></div>
        <div>{{ goodsinfo.goodsNumber }}</div>
        <div><span class="redtext">￥{{ goodsinfo.totalPrice }}</span></div>
      </div>
    </div>

    <div class="orderinfo_list_foot">
        <div>
          <p>快递费用：<span class="redtext">￥{{ goodsinfo.courierFee }}</span></p>
          <p>实付款：<span class="bigredtext">￥{{ goodsinfo.orderPayment }}</span></p>
        </div>
    </div>

  </div>
</template>
<script>

import {
  getOrderDetails
} from '@/utils/http.js'

export default {
  // orderId
  data() {
    return {
      statustext:{
        0:'待支付',
        1:'订单取消',
        2:'待收货',
        3:'已收货',
        4:'退换货',
      },
      orderStatus:null,
      goodsinfo:{}
    }
  },
  created(){
    this.getdatainfo();
    this.orderStatus=this.$route.query.orderStatus;
  },
  methods:{
    async getdatainfo(){
      let res = await getOrderDetails({orderId:this.$route.query.orderId});
      if(res.status!=0)return;
      this.goodsinfo = res.data;
      console.log( this.goodsinfo);

      
    }
  }
}
</script>
<style lang="less" scoped>
p {
  margin: 0px;
}
.orderinfo_box {
  min-height: 820px;
  background-color: #2B2B2B;
  .orderinfo_status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 51px 0px;
    .orderinfo_status_icon {
      width: 50px;
      height: 50px;
      background: url('~@/assets/goodsOrder/resquer_icon.png') no-repeat center center;
      background-size: 100% 100%;
      margin-bottom: 27px;
    }

    .orderinfo_status_text {
      font-size: 20px;
      font-weight: 800;
      color: #D5A223;
      margin-bottom: 25px;
    }

    p {
      margin-top: 15px;
    }

  }

  .orderinfo_list_view {
    border-top: 1px solid #cccccc7d;
    border-bottom: 1px solid #cccccc7d;
    .orderinfo_list_number {
      height: 80px;
      padding: 0px 50px;
      line-height: 80px;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
    }

    .orderinfo_list_table {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 400;
      height: 180px;
      color: #FFFFFF;
      >div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      >div:first-child {
        flex: 3;
        .goodimg {
          width: 100px;
          height: 100px;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .goodimgtext {
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .tbhead {
      height: 80px;
      background: #343434;
      color: #CCCCCC;
    }

  }

  .orderinfo_list_foot {
    display: flex;
    justify-content: end;
    font-size: 18px;
    font-weight: 400;
    color: #CCCCCC;
    >div {
      padding: 50px 50px 40px;
      text-align: right;
    }
    
  }
}
.redtext{
  font-weight: 800;
  color: #FF0000;
}
.bigredtext{
  font-size: 30px;
  font-weight: 800;
  color: #FF0000;
}
</style>
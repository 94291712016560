<template>
<div class="specification" >
    <div class="specification_name">{{ item.specName }}：</div>
    <div class="specification_list">
      <div 
        v-for="(itmes,index) in item.lists" 
        :key='index'
        :class="optionalindex==index?'specification_listactive':''" 
        @click="optionalSize(itmes,index)"
      >{{ itmes.itemName }}</div>
    </div>
  </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object
        }
    },
    data() {
        return {
            optionalindex:0,
            activeitem:{}
        }
    },
    created(){
      this.onActiveItem();
    },
    methods:{
        optionalSize(item,i){
            this.optionalindex=i;
            this.activeitem = item;
            this.$emit('speciChange',item)
        },
        onActiveItem(){
          this.activeitem = this.item.lists[this.optionalindex];
        }
    }
}
</script>
<style lang="less" scoped>
.specification {
    display: flex;
    .specification_name{
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
    }

    .specification_list {
      width: 350px;
      display: flex;
      flex-wrap: wrap;
      >div {
        height: 40px;
        margin-right: 13px;
        background: #3B4871;
        padding: 0px 15px;
        border-radius: 6px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-weight: 800;
        color: #FFFFFF;
        margin-bottom: 12px;
        cursor: pointer;
      }

      .specification_listactive {
        background: #D8A931;
      }
    }
  }
    
</style>
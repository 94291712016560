<template>
  <div></div>
</template>

<script>
import { setToken, setSid } from "@/utils/auth";
export default {
  data() {
    return {
      token: "",
    };
  },
  created() {
    const {token,sid}=this.$route.query;
    if (token) {
      setToken(token);
      if(sid)setSid(sid);
      this.$router.push({ path: "/interior" });
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>

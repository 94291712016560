import Cookies from 'js-cookie'

const TokenKey = 'token'
// const AuthToken = 'token'

// 登录的token
// export function getToken() {
//   return localStorage.getItem(AuthToken)
// }
// export function setToken(token) {
//   return localStorage.setItem(AuthToken, token)
// }
// export function removeToken() {
//   return localStorage.removeItem(AuthToken)
// }
// export function setSid(id) {
//   return localStorage.setItem('Sid', id)
// }
// export function getSid() {
//   return localStorage.getItem('Sid')
// }
// 登录的token存入Cookies
export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function setSid(id) {
  return Cookies.set('Sid', id)
}
export function getSid() {
  return Cookies.get('Sid')
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { attrs: { endTime: _vm.endTime } },
    [
      _vm._t("default", function () {
        return [_vm._v("\n    " + _vm._s(_vm.content) + "\n  ")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="goods-container">
    <div class="backBtn">
      <div class="back" @click="$router.back()">
        <i class="el-icon-back"></i>返回上一页
      </div>
      <div class="backHome back" @click="onRoute(-1)">
        <i class="el-icon-back"></i>返回首页
      </div>
    </div>

    <div class="goods">
      <img :src="goodsInfo.image" alt="" class="img" />
      <div class="info">
        <div class="name">{{ goodsInfo.title }}</div>
        <div class="block-num">
          <div class="leave">
            <div class="txt">剩余</div>
            <div class="num">
              {{ goodsInfo.number - goodsInfo.sellNumber }}份
            </div>
          </div>
          <div class="total">发售总量：{{ goodsInfo.number }}份</div>
        </div>
        <div class="date">
          <div class="txt">发行日期：{{ goodsInfo.sellTime | formatDate }}</div>
          <div class="txt">发行商： {{ goodsInfo.companyName }}</div>
        </div>
        <div class="code">
          哈希值：{{goodsInfo.chainHash}}
        </div>
        <div class="happy-hour">
          <div class="box">
            <div class="title">售价</div>
            <div class="price-footer">
              <div class="price-new"><i>￥</i>{{ goodsInfo.price }}</div>
              <div class="price-old" v-if="goodsInfo.isDiscount">
                <div class="discount">{{ goodsInfo.discountValueStr }}</div>
                <!-- <div class="discount">{{}}</div> -->
                <div class="txt">￥{{ goodsInfo.originalPrice }}</div>
              </div>
            </div>
          </div>
          <div class="box" v-if="goodsInfo.isDiscount">
            <div class="title">优惠时间</div>
            <div class="time">
              <djs
                class="pay-time"
                :djsType="'deadTime'"
                :endTime="time"
                @timeUp="callback()"
              />
            </div>
          </div>
        </div>
        <div class="block-buy">
          <div
            class="btn"
            :class="!agreeChecked ? 'disable' : ''"
            @click="onPay"
          >
            购买
          </div>
          <div class="agree">
            <div class="checkbox-agree">
              <el-checkbox
                v-model="agreeChecked"
                @change="onAgreeChange"
              ></el-checkbox>
              <!-- <input
                type="checkbox"
                id="checkbox1"
                class="checkbox-agree"
                value="同意" /> -->
            </div>
            已经同意
            <div class="link" @click="diaologShow = true">
              《艺集虚拟商品用户协定》
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="details">
        <div class="title">商品详情</div>
        <div class="txt" v-html="goodsInfo.content"></div>
      </div>
      <div class="recommend">
        <div class="title">推荐商品</div>
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in goodsList"
            :key="index"
            @click="onRoute(item)"
          >
            <!-- company_id: 1
            goods_id: 42
            image: "https://ipqncdn1.artuns.com/Fn5OrLEjHkqs3v_yS0v_9FWwuMMM"
            price: "100.01"
            title: "3333小艺角色设计之骨头人戴帽子2222" -->
            <img :src="item.image" :alt="item.title" class="img" />
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="goods-footer">
                <div class="price-new"><i>￥</i>{{ item.price }}</div>
                <div class="price-old" v-if="item.isDiscount">
                  ￥{{ item.originalPrice }}
                </div>
                <div class="name">{{ item.companyName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="《艺集虚拟商品用户协定》"
      :visible.sync="diaologShow"
      width="30%"
    >
      <span>这是一段协定</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="diaologShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="支付宝扫码" :visible.sync="codeShow" width="30%" @close="onClose">
      <img class="code" :src="codeImg" alt="" />
      <div class="tips">支付成功后，请关闭支付窗口</div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
import djs from "@/components/djs/djs";
import { formatDate } from "@/utils/timeStamp.js";
import { getSid } from "@/utils/auth";
export default {
  components: { djs },
  // 过滤时间
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
    toNum(percent) {
      var num = (percent.replace("%", "") / 100) * 10;
      parseFloat(num * 10).toFixed(2);
      return num;
    },
  },
  data() {
    return {
      codeShow: false,
      codeImg: "",
      goodId: 0,
      time: 0,
      count: "",
      timer: null,
      agreeChecked: false,
      diaologShow: false,
      goodsInfo: {},
      goodsList: [], //推荐商品
      loading:null
    };
  },
  watch: {
    $route(res) {
      this.goodId = res.query.goodId;
      this.dressId = res.query.dressId;
      this.getGoodsDetails();
    },
  },
  created() {
    this.goodId = this.$route.query.goodId;
    this.dressId = this.$route.query.dressId;
    this.getGoodsDetails();
    this.getGoodsListsBySort();
  },
  methods: {
    getGoodsDetails() {
      this.$http
        .post(`api/metaverse/goods/getGoodsDetails?goodsId=${this.goodId}&dressId=${this.dressId}`)
        .then((res) => {
          const { goodsInfo } = res.data;
          this.goodsInfo = goodsInfo;
          this.time = goodsInfo.discountEndTime;
        });
    },
    getGoodsListsBySort() {
      this.$http.post("api/metaverse/goods/getGoodsListsBySort").then((res) => {
        const { goodsList } = res.data;
        this.goodsList = goodsList;
      });
    },
    onAgreeChange(val) {
      console.log(val);
    },
    onBuy() {},
    toCanvas(code) {
      QRCode.toDataURL(
        code,
        { errorCorrectionLevel: "L", margin: 2, width: 400 },
        (error, url) => {
          if (error) console.log(error);
          this.codeImg = url;
          this.codeShow = true;
        }
      );
    },
    openFullScreen() {
       this.loading = this.$loading({
          lock: true,
          text: '加载中，请耐心等待...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    onPay() {
      if (!this.agreeChecked) return;
      const sid = getSid();
      const codeCount = 0;
      let data = { goodsId: this.goodId, sid };
      this.openFullScreen()

      this.$http
        .post(`api/metaverse/goods/buyGoods`, data)
        .then((res) => {
          this.loading.close();
          if (res.status == 0) {
            this.toCanvas(res.data.html);

            // 将定时器名字赋值到变量中           
            this.count = codeCount;
            this.timer = setInterval(() => {
              this.callback(res.data.outTradeNo);
              if (this.count >= 28800) {
                clearInterval(this.timer);
                this.timer = null;
                this.showWxCode = false;
              } else {
                this.count++;
              }
            }, 3000);            
            
          }
        })
        .catch((err) => {
          this.loading.close();
          this.$message(err.msg);
        });
    },

    callback(outTradeNo) {
      let data = { outTradeNo:outTradeNo };
      this.$http
        .post(`api/metaverse/common/checkPay`, data)
        .then((res) => {
          this.codeShow = false;
          clearInterval(this.timer);
        })
        .catch((err) => {
          // this.$message(err.msg);
        });
    },
    onClose(){
      this.codeShow = false;
      clearInterval(this.timer);
    },
    onRoute(item) {
      
        if(item.type==3){
            this.$router.push(`/shops/physicalGoodsInfo?goodId=${item.goodsId}`);
        }else if(item==-1){
            // 返回首页
            this.$router.push(`/shops`);
        }else{
            this.$router.push(`/shops/detail?goodId=${item.goodsId}`);
        }
    },
  },
};
</script>

<style lang="less" scoped>
.goods-container {
  background: linear-gradient(180deg, #333c58 0%, #10161b 100%);
  width: 100%;
  padding: 40px 100px;
  .backBtn {
    display: flex;
    align-items: center;
  }
  .back {
    width: 150px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    text-align: center;
    font-size: 22px;
    color: #fff;
    margin-bottom: 20px;
    margin-right: 30px;
    cursor: pointer;
    .el-icon-back {
      font-size: 32px;
      color: #fff;
      line-height: 1;
      margin-right: 5px;
    }
  }
  .backHome {
    width: 130px;
  }
  /deep/.el-dialog__wrapper {
    .el-dialog {
      width: 500px !important;
      border-radius: 10px;
      .el-dialog__header {
        text-align: center;
        font-weight: 700;
      }
      .el-dialog__body {
        padding: 0;
        padding-bottom: 30px;
      }
    }
    .code {
      width: 500px;
      height: 500px;
    }
    .tips {
      text-align: center;
    }
  }
  .goods {
    display: flex;
    .img {
      width: 700px;
      height: 460px;
      margin-right: 60px;
    }
    .info {
      flex: 1;
      .name {
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: 700;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .block-num {
        display: flex;
        justify-content: space-between;
        margin: 28px 0 14px;
        font-size: 18px;
        .leave {
          display: flex;
          align-items: center;
          height: 40px;
          background: #0d1323;
          border-radius: 6px;
          color: #fff;
          line-height: 40px;
          overflow: hidden;
          .txt {
            width: 70px;
            height: 40px;
            text-align: center;
            background: linear-gradient(to right, #efbe40, #c49623);
            
          }
          .num {
            width: 100px;
            text-align: center;
          }
        }
        .total {
          color: #999;
        }
      }
      .date {
        display: flex;
        align-items: center;
        color: #ccc;
        font-size: 18px;
        line-height: 60px;
        .txt {
          margin-right: 20px;
        }
      }
      .code {
        margin-bottom: 18px;
        color: #ccc;
        font-size: 18px;
        line-height: 50px;
      }
      .happy-hour {
        border-top: 1px solid #444444;
        display: flex;
        .box {
          padding: 30px 0 30px 30px;
          // flex: 1;
          .title {
            margin-bottom: 30px;
            color: #fff;
            font-size: 18px;
          }
          .price-footer {
            display: flex;
            align-items: flex-end;
            .price-new {
              margin-right: 18px;
              font-size: 36px;
              line-height: 1;
              color: #ff0000;
              font-weight: 700;
              i {
                font-size: 18px;
                font-style: initial;
              }
            }
            .price-old {
              display: flex;
              align-items: flex-end;
              .txt {
                color: #959595;
                font-size: 14px;
                text-decoration: line-through;
              }
            }
            .discount {
              height: 30px;
              margin-right: 10px;
              line-height: 30px;
              border-radius: 6px;
              text-align: center;
              padding: 0 10px;
              font-size: 18px;
              color: #fff;
              font-weight: 700;
              background: linear-gradient(to right, #efbe40, #c49623);
            }
          }
          .time {
            display: flex;
            align-items: center;
            letter-spacing: 2px;
            font-size: 28px;
            color: #fff;
            font-weight: 700;
          }
        }
      }
      .block-buy {
        display: flex;
        align-items: center;
        .btn {
          width: 120px;
          height: 60px;
          margin-right: 60px;
          line-height: 60px;
          text-align: center;
          background: #d5a223;
          border-radius: 6px;
          color: #fff;
          font-size: 26px;
          font-weight: 700;
          cursor: pointer;
          &.disable {
            background: #888;
            color: #bbb;
            cursor: no-drop;
          }
        }
        .agree {
          display: flex;
          font-size: 18px;
          color: #fff;
          ::v-deep.checkbox-agree {
            width: 26px;
            height: 26px;
            margin-right: 10px;
            .el-checkbox {
              .el-checkbox__inner {
                width: 26px;
                height: 26px;
                background: #191919;
                border: none;
                &::after {
                  width: 5px;
                  height: 10px;
                  left: 10px;
                  top: 6px;
                  border-right: 2px solid #ff0000;
                  border-bottom: 2px solid #ff0000;
                  transform: translate(-50%, -50%) scale(0);
                  transition: transform 0.15s ease-in;
                }
              }
              &.is-checked {
                .el-checkbox__inner {
                  &::after {
                    transform: rotate(45deg);
                  }
                }
              }
            }
            input[type="checkbox"] {
              position: relative;
              display: inline-block;
              appearance: none;
              -webkit-appearance: none;
              width: 26px;
              height: 26px;
              outline: none;
              background-color: #191919;
              margin: 0;
              cursor: pointer;
              &:checked {
                &::after {
                  border-right: 2px solid #ff0000;
                  border-bottom: 2px solid #ff0000;
                  transform: rotate(45deg);
                  display: block;
                }
              }
              &::after {
                display: inline-block;
                content: "";
                width: 5px;
                height: 10px;
                position: absolute;
                left: 10px;
                top: 6px;
                transform: translate(-50%, -50%) scale(0);
                transition: transform 0.15s ease-in;
              }
            }
          }
          .link {
            color: #ff0000;
            text-decoration: none;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    padding-top: 45px;
    .details {
      flex: 1;
      display: flex;
      flex-direction: column;
      .title {
        margin-bottom: 25px;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
      }
      .txt {
        font-size: 16px;
        color: #ccc !important;
        line-height: 32px;
        img {
          width: 100%;
          margin: 30px 0;
        }
      }
    }
    .recommend {
      display: flex;
      flex-direction: column;
      margin-left: 60px;
      .title {
        margin-bottom: 25px;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
      }
      .list {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          flex-direction: column;
          color: #fff;
          text-decoration: none;
          .img {
            width: 390px;
            height: 260px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .info {
            padding: 10px 0 30px;
            .title {
              margin-bottom: 6px;
              font-size: 18px;
              font-weight: 700;
            }
          }
          .goods-footer {
            display: flex;
            align-items: center;
            width: 100%;
            .price-new {
              margin-right: 10px;
              font-size: 20px;
              color: #ff0000;
              font-weight: 700;
              i {
                font-size: 12px;
                font-style: initial;
              }
            }
            .price-old {
              color: #959595;
              font-size: 14px;
              text-decoration: line-through;
            }
            .name {
              font-size: 14px;
              color: #d5a223;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <!-- 土地购买 -->
  <div class="purchase">
    <div class="areaBoxs">
      <div
        class="landList"
        v-infinite-scroll="loadData"
        infinite-scroll-disabled="disabled"
      >
        <div
          class="items"
          v-for="(item, index) in landList"
          :key="index"
          :class="curInx == index ? 'cur' : ''"
          @click="onChooseArea(index, item.landId)"
        >
          <img :src="item.image" alt="" class="bg" />
          <div class="name">{{ item.landName }}</div>
          <div class="sellStatus">
            已购：
            <div class="sold">{{ item.buyCount }}</div>
            /
            <div class="total">{{ item.totalCount }}</div>
          </div>
        </div>
      </div>
      <!-- 这个div一定要放在使用指令的div里面-->
      <!-- <div align="center" class="pullStatus" v-if="landList.length > 0">
        <div class="drawer-footer">
          <span v-if="pullStatus === 0"> 滚动加载更多... </span>
          <span v-if="pullStatus === 1">
            加载中...
            <i class="el-icon-loading"></i>
          </span>
          <span v-if="pullStatus === 2"> -- 没有更多了-- </span>
        </div>
      </div> -->
      <el-empty
        description="暂无数据"
        v-if="landList.length == 0"
        style="height: 100%; overflow-y: auto; text-align: center"
      ></el-empty>
    </div>

    <div class="details">
      <div class="tablist">
        <div v-for="(item ,index) in tablist" 
        :key="'menpai'+index" 
        class="items" 
        :class="curNavInx == index?'cur':''"
        @click="onTab(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="landLists" 
        v-infinite-scroll="loadData"
        infinite-scroll-disabled="disabled">
        <div
          class="items"
          v-for="(item, index) in doorplateList"
          :key="item.doorplateId"
          :class="[
            curDoorInx === index ? 'cur' : '',
            item.memberId ? 'isSold' : '',
          ]"
          @click="!item.memberId ? onChoseDoorplate(index, item) : onPrompt()"
        >
          <div class="ft">
            <div class="numberPlate">{{ item.doorplateName }}</div>
            <div class="areaInfo">占地面积：{{ item.covered }}㎡</div>
          </div>
          <div class="rt" v-if="!item.memberId">
            <div class="price">
              ￥
              <div class="num">{{ item.price }}</div>
            </div>
          </div>
          <div class="rt" v-else>
            <div class="imgs"><img :src="item.avator" alt="" /></div>
            <div class="status">已售出</div>
          </div>
        </div>
        <el-empty
          description="暂无数据"
          v-if="doorplateList.length == 0"
          style="height: 100%; overflow-y: auto; text-align: center"
        ></el-empty>
      </div>
    </div>
    <div class="buyBox">
      <div class="priceInfo">
        价格：
        <div class="price">
          ￥
          <div class="num">{{ `${doorplateId ? amount : "0"}` }}</div>
        </div>
      </div>
      <div class="btns">
        <div class="viewGround" :class="!doorplateId ? 'disable' : ''" @click="onLook">查看地面</div>
        <div
          class="buyLand"
          :class="!doorplateId ? 'disable' : ''"
          @click="onPay()"
        >
          购买土地
        </div>
      </div>
    </div>
    <!-- 购买土地提示 -->
    <el-dialog title="支付宝扫码" :visible.sync="codeShow" width="30%" @close="onClose">
      <img class="code" :src="codeImg" alt="" />
      <div class="tips">支付成功后，请关闭支付窗口</div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { getSid } from "@/utils/auth";
export default {
  data() {
    return {
      curInx: 0,
      curNavInx:0,
      type:0,//0全部1已售出2未售出
      curDoorInx: null,
      landId: "", //小区地块id
      doorplateId: "", //小区门牌id
      amount: "", //支付金额
      codeShow: false,
      codeImg: "",
      count: "",
      timer: null,
      loading: false,
      noMore: false,
      //pullStatus: 1, //加载状态 0：上拉加载 1：加载中 2：没有更多数据
      page: 1,
      totalPage: 0,
      //pageSize: 6,
      tablist:['全部','已售出','未售出'],
      landList: [], //小区地块列表
      doorplateList: [], //小区门牌列表
    };
  },
  created() {},
  mounted() {},
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
  // watch: {
  //   projectSpaceId: function (value, oldValue) {
  //     this.landList = [];
  //     this.page = 1;
  //     this.pullStatus = 0;
  //     this.getLandList();
  //   },
  // },
  methods: {
    onLook(){
      if (!this.doorplateId) return;
      const currtItem=this.doorplateList[this.curDoorInx]
      //  console.log({ "landId": this.doorplateId,"sceneId":currtItem.sceneId });
      if (window.vuplex) {
        window.vuplex.postMessage({ "landId": this.doorplateId,"sceneId":currtItem.sceneId });
      }
    },
    loadData() {
      this.loading = true;
      setTimeout(() => {
        this.getLandList();
        this.loading = false;
        this.page++;
      }, 500);
    },
    onTab(index){
      this.type = index;
      this.curNavInx = index;
      this.doorplateId = '';
      this.curDoorInx = null;
      this.getDoorplateList(this.landId);
    },
    onChooseArea(index, id) {
      this.curInx = index;
      this.landId = id;
      this.doorplateId = '';
      this.curDoorInx = '';
      this.type = 0;
      this.curNavInx = 0;
      this.getDoorplateList(this.landId);
    },
    getLandList() {
      this.$http
        .post(`api/metaverse/land/getLandList?page=${this.page}`)
        .then((res) => {
          const { landList, page } = res.data;
          this.landList = this.landList.concat(landList);
          if (res.data.totalPage < this.page) {
            this.noMore = true;
          }
          if (page == 2) {
            this.landId = this.landList[0].landId;
            // console.log(this.landId);
            this.getDoorplateList(this.landId);
          }
        });
    },
    onPrompt() {
      this.$toast("该区域已售卖!");
    },
    onChoseDoorplate(index, item) {
      this.curDoorInx = index;
      this.doorplateId = item.doorplateId;
      this.landId = item.landId;
      this.amount = item.price;
    },
    getDoorplateList(id) {
      this.$http
        .post(`api/metaverse/land/getDoorplateList?landId=${id}&page=1&type=${this.type}`)
        .then((res) => {
          const { doorplateList } = res.data;
          this.doorplateList = doorplateList;
        });
    },
    toCanvas(code) {
      QRCode.toDataURL(
        code,
        { errorCorrectionLevel: "L", margin: 2, width: 350 },
        (error, url) => {
          if (error) console.log(error);
          // console.log(url);
          this.codeImg = url;
          this.codeShow = true;
        }
      );
    },
    onPay() {
      if (!this.doorplateId) return;
      const sid = getSid();
      const codeCount = 0;
      let data = {
        landId: this.landId,
        sid: sid,
        doorplateId: this.doorplateId,
      };
      // console.log(data);
      this.$http
        .post(`api/metaverse/land/buyLand`, data)
        .then((res) => {
          if (res.status == 0) {
            this.toCanvas(res.data.html);
            // 将定时器名字赋值到变量中           
            this.count = codeCount;
            this.timer = setInterval(() => {
              this.callback(res.data.outTradeNo);
              if (this.count >= 28800) {
                clearInterval(this.timer);
                this.timer = null;
                this.showWxCode = false;
              } else {
                this.count++;
              }
            }, 3000);
          }
        })
        .catch((err) => {
          this.$message(err.msg);
        });
    },
    callback(outTradeNo) {
      let data = { outTradeNo:outTradeNo };
      this.$http
      .post(`api/metaverse/common/checkPay`, data)
      .then((res) => {
        this.codeShow = false;
        clearInterval(this.timer);
      })       
    },
    onClose(){
      this.codeShow = false;
      clearInterval(this.timer);
    }
  },
  beforeDestroy() {
    this.landList = []; //清空数组
  },
};
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.purchase {
  width: 100%;
  height: 100vh;
  padding: 40px 0 40px 40px;
  background-color: #333c58;
  box-sizing: border-box;
  .areaBoxs {
    position: fixed;
    left: 40px;
    top: 40px;
    width: 400px;
    padding-right: 20px;
    height: calc(100% - 40px);
    overflow-y: scroll;
    z-index: 1;
  }
  .areaBoxs::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .areaBoxs::-webkit-scrollbar-track {
    // background-color: transparent;
    background-color: rgba(192, 169, 22, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  .areaBoxs::-webkit-scrollbar-thumb {
    background-color: rgb(147, 147, 153, 0.1);
    // background: transparent;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  .landList {
    width: calc(100% - 20px);
    .items {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      border-radius: 10px;
      box-sizing: border-box;
      cursor: pointer;
      // &:last-child {
      //   margin-bottom: 0;
      // }
      &.cur {
        border: solid 2px #d5a223;
        &::before {
          content: "";
          display: block;
          position: absolute;
          right: -40px;
          top: 50%;
          width: 19px;
          height: 17px;
          margin-top: -9px;
          background: url(https://ipqncdn1.artuns.com/5ada4ef1341dc39b02a34a88ea0a4af1_arrow.png)
            no-repeat center;
          background-size: 19px 17px;
          z-index: 2;
        }
      }

      img.bg {
        display: block;
        width: 100%;
        border-radius: 10px;
      }
      .name {
        position: absolute;
        left: 15px;
        bottom: 15px;
        font-size: 20px;
        font-weight: 800;
        color: #ffffff;
        z-index: 2;
      }
      .sellStatus {
        position: absolute;
        right: 15px;
        bottom: 15px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #ffffff;
        z-index: 2;
      }
    }
  }
  .pullStatus {
    padding-right: 20px;
    padding-bottom: 20px;
    .drawer-footer {
      span {
        color: #fff;
      }
    }
  }
  .details {
    padding-left: 420px;
    padding-top: 80px;
    height: calc(100vh - 80px);
    .tablist{
      position: fixed;
      top: 10px;
      display: flex;
      width: calc(100% - 548px);
      border-bottom: 1px solid #ffffff20;
      z-index: 99;
      .items{
        min-width: 180px;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #ccc;
        line-height: 80px;
        cursor: pointer;
        &.cur {
          position: relative;
          color: #D6A324;
          &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: calc(50% - 50px);
            width: 100px;
            height: 2px;
            background: #D6A324;
          }
        }
      }
    }
    .landLists {
      position: relative;
      width: 100%;
      height: 100%;
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 80px;
      background: linear-gradient(180deg, #333c58 0%, #10161b 100%);
      overflow-y:scroll ;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        // background-color: transparent;
        background-color: rgba(192, 169, 22, 0.1);
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(147, 147, 153, 0.1);
        // background: transparent;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
      .noMore{
        flex: 0 0 100%;
        color: #ccc;
        text-align: center;
      }
      .items {
        position: relative;
        float: left;
        display: flex;
        justify-content: space-between;
        width: calc(50% - 40px);
        padding: 25px 30px;
        margin-right: 40px;
        margin-bottom: 30px;
        background: url(https://ipqncdn1.artuns.com/344bc29ccce11bee851310e044b334ca_158@2x.png)
          no-repeat center;
        background-size: cover;
        border-radius: 14px;
        overflow: hidden;
        box-sizing: border-box;
        cursor: pointer;
        &.cur {
          background-image: url(https://ipqncdn1.artuns.com/98417f76cf38cd7aa46784cc1ab30df5_159@2x.png);
        }

        .ft {
          .numberPlate {
            font-size: 28px;
            font-weight: 800;
            color: #ffffff;
          }
          .areaInfo {
            font-size: 20px;
            color: #eeeeee;
            margin-top: 30px;
          }
        }
        .rt {
          padding-top: 45px;
          .price {
            display: flex;
            align-items: baseline;
            font-size: 20px;
            font-weight: bold;
            color: #0436b5;
            .num {
              font-size: 40px;
            }
          }
        }
        &.isSold {
          background-image: none;
          background-color: #343434;
          .ft {
            .numberPlate,
            .areaInfo {
              color: #999999;
            }
          }
          .rt {
            padding-top: 0;
            text-align: center;
            .imgs {
              width: 50px;
              height: 50px;
              margin: 0 auto 10px;
              border-radius: 100%;
              overflow: hidden;
              img {
                display: block;
                width: 50px;
                height: 50px;
              }
            }
            .status {
              font-size: 24px;
              font-weight: 800;
              color: #ffffff;
            }
          }
        }
      }
      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }
  .buyBox {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 460px);
    height: 120px;
    padding: 30px 45px;
    background: linear-gradient(180deg, #333c58 0%, #1b2731 100%);
    border-radius: 0px 0px 0px 0px;
    z-index: 1;
    .priceInfo {
      display: flex;
      align-items: baseline;
      font-size: 26px;
      font-weight: 800;
      color: #ffffff;
      .price {
        display: flex;
        align-items: baseline;
        font-size: 20px;
        color: #d6a324;
        .num {
          font-size: 38px;
        }
      }
    }
    .btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .viewGround,
      .buyLand {
        width: 160px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-radius: 30px;
        font-size: 24px;
        font-weight: 800;
        color: #ffffff;
        cursor: pointer;
      }
      .viewGround {
        background: linear-gradient(
          109deg,
          #00cbff 0%,
          #07bde6 60%,
          #0bb3d6 100%
        );
        &.disable {
          background: #958787;
        }
      }
      .buyLand {
        margin-left: 30px;
        background: linear-gradient(109deg, #d5a223 0%, #ffd263 100%);
        &.disable {
          background: #958787;
        }
      }
    }
  }
  /deep/.el-dialog {
    .el-dialog__body {
      padding-top: 5px;
      .code {
        display: block;
        margin: 0 auto;
      }
      .tips {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.loadAreaData,
                expression: "loadAreaData",
              },
            ],
            staticClass: "areaBoxs",
            attrs: { "infinite-scroll-disabled": "areaNoMore" },
          },
          _vm._l(_vm.interiorLsit, function (item, index) {
            return _c(
              "div",
              {
                key: "nj" + index,
                staticClass: "item",
                class: _vm.curItem.templateId == item.templateId ? "cur" : "",
                on: {
                  click: function ($event) {
                    return _vm.onChoseInterior(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "txt" }, [
                  _vm._v("内景面积：" + _vm._s(item.covered) + "m²"),
                ]),
                _c("div", { staticClass: "price" }, [
                  _vm._v("\n          ￥"),
                  _c("div", { staticClass: "num" }, [
                    _vm._v(_vm._s(item.price)),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "price" }, [
            _vm._v("\n        价格："),
            _vm.curItem.templateId
              ? _c("div", { staticClass: "num" }, [
                  _c("i", [_vm._v("￥")]),
                  _vm._v(_vm._s(_vm.curItem.price)),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "btns",
              class: !_vm.curItem.templateId ? "disable" : "",
            },
            [
              _c(
                "div",
                { staticClass: "btn btn-look", on: { click: _vm.onEnter } },
                [_vm._v("查看内景")]
              ),
              _c(
                "div",
                { staticClass: "btn btn-buy", on: { click: _vm.onPay } },
                [_vm._v("购买")]
              ),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "purchased" }, [
        _c("div", { staticClass: "title" }, [_vm._v("我的购买")]),
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.loadOrderData,
                expression: "loadOrderData",
              },
            ],
            staticClass: "list",
            attrs: { "infinite-scroll-disabled": "orderNoMore" },
          },
          [
            _vm._l(_vm.myOrderList, function (item, index) {
              return _c("div", { key: "wdgm" + index, staticClass: "item" }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.goodsName)),
                ]),
                _c("div", { staticClass: "txt" }, [
                  _vm._v("内景面积：" + _vm._s(item.covered) + "m²"),
                ]),
              ])
            }),
            _vm.myOrderList.length == 0
              ? _c("el-empty", {
                  staticStyle: {
                    width: "100%",
                    height: "100%",
                    "text-align": "center",
                  },
                  attrs: { description: "暂无数据" },
                })
              : _vm.orderNoMore
              ? _c("div", { staticClass: "noMore" }, [
                  _vm._v(" -- 没有更多了-- "),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "支付宝扫码", visible: _vm.codeShow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.codeShow = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c("img", {
            staticClass: "code",
            attrs: { src: _vm.codeImg, alt: "" },
          }),
          _c("div", { staticClass: "tips" }, [
            _vm._v("支付成功后，请关闭支付窗口"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "tabs" },
      _vm._l(_vm.sessionList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "item",
            class: _vm.session == item ? "cur" : "",
            on: {
              click: function ($event) {
                return _vm.onChooseSession(item)
              },
            },
          },
          [_vm._v(_vm._s(item) + "级")]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadData,
            expression: "loadData",
          },
        ],
        staticClass: "list",
        attrs: { "infinite-scroll-disabled": "disabled" },
      },
      [
        _vm._l(_vm.landList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item",
              class: _vm.curItem.memberId == item.memberId ? "cur" : "",
              on: {
                click: function ($event) {
                  return _vm.onChoseDoorplate(index, item)
                },
              },
            },
            [
              _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
              _c("div", { staticClass: "realName" }, [
                _vm._v("真实姓名：" + _vm._s(item.realName)),
              ]),
            ]
          )
        }),
        _vm.landList.length == 0
          ? _c("el-empty", {
              staticStyle: {
                width: "100%",
                height: "100%",
                "text-align": "center",
              },
              attrs: { description: "暂无数据" },
            })
          : _vm.noMore
          ? _c("div", { staticClass: "noMore" }, [_vm._v(" -- 没有更多了-- ")])
          : _vm._e(),
      ],
      2
    ),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "choose" }, [
        _vm._v("\n      已选中："),
        _vm.curItem && _vm.curItem.name
          ? _c("div", { staticClass: "num" }, [
              _vm._v(
                _vm._s(_vm.curItem.name) +
                  "（" +
                  _vm._s(_vm.curItem.realName) +
                  "）"
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "btns" }, [
        _c(
          "div",
          { staticClass: "btn btn-look", on: { click: _vm.onjoinScence } },
          [_vm._v("进入")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="addres_item" :class="isDefaultAddress?'active_item':''" @click="changeAddress">
    <div class="addres_name">{{ dataobj.contact }}</div>
    <div class="addres_number">{{ dataobj.telephone }}</div>
    <div class="addres_info">
      <p>
        <span v-if="dataobj.provinceName">{{ dataobj.provinceName }}</span>
        <span v-if="dataobj.cityName">{{ dataobj.cityName }}</span>
        <span v-if="dataobj.districtName">{{ dataobj.districtName }}</span>
      </p>
    </div>
    <div class="addres_info">
      <p>
        {{ dataobj.content?dataobj.content:'尚未填写' }}
      </p>
    </div>
    <div class="addres_i">
      <span v-show="dataobj.isDefault==1">默认地址</span>
      <span v-show="dataobj.isDefault==0" class="activeadd" @click.stop="setDefaultAddress">设为默认地址</span>
    </div>
  </div>
</template>
<script>
import { 
  setDefaultAddress,
 } from "@/utils/http.js";
export default {
  props:{
    isDefaultAddress:{
      default:false,
    },
    dataobj:{
      type:Object,
    }
  },
  methods: {
    changeAddress(){
      this.$emit('changeAddress')
    },
    async setDefaultAddress(){
      let params = {...this.dataobj,isDefault:1};
      let data = await setDefaultAddress(params);
      if(data.status==0){
        this.$message({
          message: '设置成功',
          type: 'success'
        });
        this.$emit('changeDefault')
      }else{
        this.$message.error('设置失败');
      }
      
    }
  },
  
}
</script>
<style lang="less" scoped>

.addres_item {
  position: relative;
  width: 280px;
  height: 210px;
  margin-right: 34px;
  margin-bottom: 28px;
  background: #383838;
  padding: 15px;
  color: #fff;

  .addres_name {
    font-size: 20px;
    font-weight: 800;
  }

  .addres_number {
    line-height: 16px;
    margin: 10px 0px;
    font-size: 16px;
    font-weight: 800;
  }

  .addres_info {
    font-size: 16px;
    margin-bottom: 10px;
    color: #CCCCCC;
    p {
      max-height: 42px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    span{
      margin-right: 17px;
    }
  }
  .addres_i {
    position: absolute;
    left: 15px;
    bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    .activeadd{
      color: #FF0000;
      user-select: none;
    }
  }
}
.active_item {
  border: 1px solid #D5A223;
}
.addres_item:nth-child(4n) {
  margin-right: 0px;
}

  
</style>
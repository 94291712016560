var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "updatainp_view" },
    [
      _vm._l(_vm.imgArr, function (item, index) {
        return _c("div", { key: index, staticClass: "fileimg" }, [
          _c("img", { attrs: { src: item } }),
          _c("i", {
            on: {
              click: function ($event) {
                return _vm.deleteimg(index)
              },
            },
          }),
        ])
      }),
      _vm._m(0),
      _c("input", {
        ref: "updataRef",
        attrs: { type: "file", id: "updataiid", accept: "image/*" },
        on: { change: _vm.fileChange },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "updataiid" } }, [
      _c("div", { staticClass: "fileicon" }, [_c("i")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "addres_item",
      class: _vm.isDefaultAddress ? "active_item" : "",
      on: { click: _vm.changeAddress },
    },
    [
      _c("div", { staticClass: "addres_name" }, [
        _vm._v(_vm._s(_vm.dataobj.contact)),
      ]),
      _c("div", { staticClass: "addres_number" }, [
        _vm._v(_vm._s(_vm.dataobj.telephone)),
      ]),
      _c("div", { staticClass: "addres_info" }, [
        _c("p", [
          _vm.dataobj.provinceName
            ? _c("span", [_vm._v(_vm._s(_vm.dataobj.provinceName))])
            : _vm._e(),
          _vm.dataobj.cityName
            ? _c("span", [_vm._v(_vm._s(_vm.dataobj.cityName))])
            : _vm._e(),
          _vm.dataobj.districtName
            ? _c("span", [_vm._v(_vm._s(_vm.dataobj.districtName))])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "addres_info" }, [
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.dataobj.content ? _vm.dataobj.content : "尚未填写") +
              "\n    "
          ),
        ]),
      ]),
      _c("div", { staticClass: "addres_i" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dataobj.isDefault == 1,
                expression: "dataobj.isDefault==1",
              },
            ],
          },
          [_vm._v("默认地址")]
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dataobj.isDefault == 0,
                expression: "dataobj.isDefault==0",
              },
            ],
            staticClass: "activeadd",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.setDefaultAddress.apply(null, arguments)
              },
            },
          },
          [_vm._v("设为默认地址")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <span :endTime="endTime">
    <slot>
      {{ content }}
    </slot>
  </span>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  props: {
    endTime: {
      type: Number,
      default: "",
    },
    endText: {
      type: String,
      default: "已结束",
    },
    dateType: {
      type: Number,
      default: 1,
    },
    djsType: {
      type: String,
      default: 'date',
    },
  },
  mounted() {
    this.countdowm(this.endTime);
  },
  methods: {
    countdowm(timestamp) {
      let self = this;
      let dayTxt,hourTxt,minTxt,secTxt;
      if(this.dateType == 1){
        dayTxt='天:';
        hourTxt='时:';
        minTxt='分:';
        secTxt='秒';
      }else{
        dayTxt='天';
        hourTxt='小时';
        minTxt='分';
        secTxt='秒';
      }
      timestamp=this.djsType=='deadTime'?timestamp:(timestamp+900);
      let timer = setInterval(function () {
        let nowTime = new Date();
        let endTime = new Date(timestamp * 1000);
        let t = endTime.getTime() - nowTime.getTime();
        if (t > 0) {
          let day = Math.floor(t / 86400000);
          let hour = Math.floor((t / 3600000) % 24);
          let min = Math.floor((t / 60000) % 60);
          let sec = Math.floor((t / 1000) % 60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = "";
          if (day > 0) {
            format = `${day}${dayTxt}${hour}${hourTxt}${min}${minTxt}${sec}${secTxt}`;
          }
          if (day <= 0 && hour > 0) {
            format = `${hour}${hourTxt}${min}${minTxt}${sec}${secTxt}`;
          }
          if (day <= 0 && hour <= 0) {
            format = `${min}${minTxt}${sec}${secTxt}`;
          }
          self.content = format;
        } else {
          self.callback()
          clearInterval(timer);
          
          self.content = '';
        }
      }, 1000);
    },
    callback(){
      this.$emit("timeUp");
    }
  },
};
</script>
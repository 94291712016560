var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goods-container" },
    [
      _c("div", { staticClass: "goods" }, [
        _c("img", {
          staticClass: "img",
          attrs: { src: _vm.goodsInfo.image, alt: "" },
        }),
        _c(
          "div",
          { staticClass: "info" },
          [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.goodsInfo.title)),
            ]),
            _c("div", { staticClass: "block-num" }, [
              _c("div", { staticClass: "leave" }, [
                _c("div", { staticClass: "txt" }, [_vm._v("剩余")]),
                _c("div", { staticClass: "num" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.activeSku ? _vm.activeSku.number : "") +
                      "份\n          "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "total" }, [
                _vm._v(
                  "发售总量：" +
                    _vm._s(
                      _vm.activeSku
                        ? _vm.activeSku.number + _vm.activeSku.sellNumber
                        : ""
                    ) +
                    "份"
                ),
              ]),
            ]),
            _vm._l(_vm.goodsInfo.specLists, function (item, index) {
              return _c("specificationItem", {
                key: index,
                attrs: { item: item },
                on: {
                  speciChange: function ($event) {
                    return _vm.speciChange($event, index)
                  },
                },
              })
            }),
            _c("div", { staticClass: "counterbox" }, [
              _c("div", { staticClass: "counterbox_name" }, [_vm._v("数量：")]),
              _c("div", { staticClass: "counterbox_num" }, [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeCountnum(-1)
                      },
                    },
                  },
                  [_vm._v("-")]
                ),
                _c("div", [_vm._v(_vm._s(_vm.countnum))]),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeCountnum(1)
                      },
                    },
                  },
                  [_vm._v("+")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "happy-hour" }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("售价")]),
                _vm.activeSku
                  ? _c("div", { staticClass: "price-footer" }, [
                      _c("div", { staticClass: "price-new" }, [
                        _c("i", [_vm._v("￥")]),
                        _vm._v(_vm._s(_vm.activeSku.price)),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.goodsInfo.isDiscount,
                              expression: "goodsInfo.isDiscount",
                            },
                          ],
                          staticClass: "price-old",
                        },
                        [
                          _c("div", { staticClass: "discount" }, [
                            _vm._v(_vm._s(_vm.activeSku.discountValueStr)),
                          ]),
                          _c("div", { staticClass: "txt" }, [
                            _vm._v("￥" + _vm._s(_vm.activeSku.originalPrice)),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.goodsInfo.isDiscount
                ? _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("优惠时间")]),
                    _c("div", { staticClass: "time" }, [
                      _c("span", [_vm._v(_vm._s(_vm.djsdata))]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "block-buy" }, [
              _c(
                "div",
                {
                  staticClass: "btn",
                  class: !_vm.agreeChecked ? "disable" : "",
                  on: { click: _vm.onPay },
                },
                [_vm._v("\n          购买\n        ")]
              ),
              _c("div", { staticClass: "agree" }, [
                _c(
                  "div",
                  { staticClass: "checkbox-agree" },
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.agreeChecked,
                        callback: function ($$v) {
                          _vm.agreeChecked = $$v
                        },
                        expression: "agreeChecked",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v("\n          已经同意\n          "),
                _c(
                  "div",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        _vm.diaologShow = true
                      },
                    },
                  },
                  [_vm._v("\n            《艺集虚拟商品用户协定》\n          ")]
                ),
              ]),
            ]),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "details" }, [
          _c("div", { staticClass: "title" }, [_vm._v("商品详情")]),
          _c("div", {
            staticClass: "txt",
            domProps: { innerHTML: _vm._s(_vm.goodsInfo.content) },
          }),
        ]),
        _c("div", { staticClass: "recommend" }, [
          _c("div", { staticClass: "title" }, [_vm._v("推荐商品")]),
          _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.goodsList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.onRoute(item)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: item.image, alt: item.title },
                  }),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("div", { staticClass: "goods-footer" }, [
                      _c("div", { staticClass: "price-new" }, [
                        _c("i", [_vm._v("￥")]),
                        _vm._v(_vm._s(item.price)),
                      ]),
                      item.isDiscount
                        ? _c("div", { staticClass: "price-old" }, [
                            _vm._v(
                              "\n                ￥" +
                                _vm._s(item.originalPrice) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.companyName)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "《艺集虚拟商品用户协定》",
            visible: _vm.diaologShow,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.diaologShow = $event
            },
          },
        },
        [
          _c("span", [_vm._v("这是一段协定")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.diaologShow = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template >
  <div class="addressList">
    <div class="addressList_item" v-for="item in addresslist" :key="item.addressId">
      <div>
        <p>{{ item.contact }} <span></span> {{ item.telephone }}</p>
        <p class="addressinfo">
          <span v-if="item.provinceName">{{ item.provinceName }}</span>
          <span v-if="item.cityName">{{ item.cityName }}</span>
          <span v-if="item.districtName">{{ item.districtName }}</span>
          <span>{{ item.content?item.content:'尚未填写' }}</span>
        </p>
      </div>
      <div>
        <i 
          :class="item.isDefault==1?'activeicon':''"
          @click="setdefualtAddress(item)"
        ></i>
        <span>设为默认地址</span>
      </div>
      <div>
        <button @click="editAddress(item.addressId)">修改地址</button>
        <button class="deletebtn" @click="deleteAddress(item)">删除</button>
      </div>
    </div>

    <div class="addressList_btn">
      <button @click="addaddress">添加收货地址</button>
    </div>

  </div>
</template>
<script>

import { 
  getAddressList,
  setDefaultAddress,
  delAddress,
 } from "@/utils/http.js";
export default {
  data() {
    return {
      addresslist:[],
    }
  },
  created() {
    this.getAddress()
  },
  methods: {
    addaddress(){
      this.$router.push('/order/addAddress')
    },

    editAddress(addressId){
      this.$router.push({path:'/order/addAddress',query:{addressId}})
    },

    async getAddress(){
      let {data,status} = await getAddressList();
      if(status!=0)return;
      this.addresslist=data.addressList;
    },

    async setdefualtAddress(obj){
      this.addresslist = this.addresslist.map((item)=>{
        if(item.addressId==obj.addressId){
          return {...item,isDefault:1}
        }else{
          return {...item,isDefault:0}
        }
      })

      let data = await setDefaultAddress({...obj,isDefault:1});
      if(data.status==0){
        this.$message({
          message: '设置成功',
          type: 'success'
        });
      }else{
        this.$message.error('设置失败');
      }
    },

    deleteAddress(item){
      this.$confirm('此操作将永久删除该地址, 是否继续?', '删除地址', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAddress({addressId:item.addressId}).then((res)=>{
          console.log(res);
          if(res.status==0){
            this.getAddress()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message.error('删除失败!');
          }
        })
        
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });

    },

    

  },
  
}
</script>
<style lang="less" scoped>
button {
  cursor: pointer;
}
.addressList {
  height: 857px;
  background: #2B2B2B;

  .addressList_item {
    display: flex;
    align-items: center;
    height: 146px;
    padding: 0px 42px;
    border-bottom: 1px solid #cccccc3f;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    >div {
      flex: 1;
      display: flex;
      align-items: center;
      i {
        width: 20px;
        height: 20px;
        background: #5A5A5A;
        border-radius: 50%;
        margin-right: 9px;
        cursor: pointer;
      }

      .activeicon {
        background: url('~@/assets/goodsOrder/acquiesce_icon.png') no-repeat center center;
        background-size:11px 7px;
        background-color: #E83D3A;
      }
      button {
        width: 140px;
        height: 50px;
        background: #D5A223;
        border-radius: 6px;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        margin-right: 40px;
      }
      .deletebtn {
        background-color: #444444;
        margin-right: 0px;
      }
    }
    >div:first-child {
      flex: 2;
      align-items: start;
      flex-direction: column;
      p {
        margin: 5px 0px;
        span {
          font-size: 16px;
          color: #999999;
          margin-right: 15px;
        }
      }
    }
  }

  .addressList_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;

    button {
      width: 150px;
      height: 50px;
      background: #D5A223;
      border-radius: 6px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}

.addressinfo {
  display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

</style>
<template>
  <div class="bigbox w">
    <div class="menu">
      <ul>
        <li :class="item.active==1?'activeItem':''" v-for="item in menuArr" :key="item.path">
          <router-link :to="item.path">
            <span>{{ item.name }}</span>
          </router-link>
          <i></i>
        </li>

      </ul>
    </div>
    <div class="body_main">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuArr:[
        {
          name:'订单管理',
          path:'/order/userCentre/orderList',
          active:0
        },{
          name:'地址管理',
          path:'/order/userCentre/addressList',
          active:0
        }
      ]
    }
  },
  watch:{
    $route:{
      handler:function(newval){
        this.menuArr=this.menuArr.map((item)=>{
          if(item.path==newval.path){
            item.active=1;
            return item;
          }else {
            item.active=0;
            return item;
          }
        })
      },
      immediate: true
    }
    
  }
}
</script>
<style lang="less" scoped>
.w {
  width: 1620px;
  margin: auto;
  margin-top: 50px;
}
.bigbox {
  display: flex;
  justify-content: space-between;
}
.menu {
  width: 300px;
  ul {
    font-size: 18px;
    font-weight: 800;
    color: #E4E4E4;
    li {
      position: relative;
      height: 80px;
      line-height: 80px;
      background-color: #2B2B2B;
      border-bottom: 1px solid #cccccc3f;
      padding-left: 30px;
      cursor: pointer;
      a {
        color: #fff;
      }
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        width: 8px;
        height: 15px;
        background: url('~@/assets/goodsOrder/rightIcon.png') no-repeat center center;
        background-size: 100% 100%;
      }
    }
    li:last-child{
      border-bottom: 0px;
    }
    .activeItem {
      color: #D5A223;
      i {
        background: url('~@/assets/goodsOrder/rightIcon_active.png') no-repeat center center;
        background-size: 100% 100%;
      }

      a {
        color: #D5A223;
      }
      &::before{
        position: absolute;
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: 3px;
        height: 26px;
        background: #D5A223;
      }
    }
  }

}
.body_main {
  width: 1280px;
}
  
</style>
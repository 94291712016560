import { render, staticRenderFns } from "./expressDelivery.vue?vue&type=template&id=260ef356&scoped=true"
import script from "./expressDelivery.vue?vue&type=script&lang=js"
export * from "./expressDelivery.vue?vue&type=script&lang=js"
import style0 from "./expressDelivery.vue?vue&type=style&index=0&id=260ef356&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260ef356",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\公司项目\\银山\\元宇宙\\MetaverseMall\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('260ef356')) {
      api.createRecord('260ef356', component.options)
    } else {
      api.reload('260ef356', component.options)
    }
    module.hot.accept("./expressDelivery.vue?vue&type=template&id=260ef356&scoped=true", function () {
      api.rerender('260ef356', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/pc/goodsOrder/expressDelivery.vue"
export default component.exports
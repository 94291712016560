import axios from 'axios';
export const baseURL = process.env.VUE_APP_API_URL
import { getToken, removeToken } from './auth'
import config from "@/utils/config.js"

/**
 * 异常处理程序
 */

/**
 * 配置request请求时的默认参数
 */
// console.log(baseURL)
const request = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
  timeout: 25 * 1000, // 请求超时时间
});

request.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
);


/**
 * 对于状态码实际是 200 的错误
 */
request.interceptors.response.use(
  response => {
    const { data } = response;
    const { status, msg } = data;

    if(response.config.url==config.qnuploadurl){
      return data
    };
    if (status !== 0) {
      if ([1001, 1002, 30000, 40000].includes(status)) { // 登录失效
        removeToken()
        // router.push({path:'/auth?sid=1001&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBUlRVTlMiLCJhdWQiOiJBUlRVTlMiLCJpYXQiOjE2NzYyNTg4NDEsIm5iZiI6MTY3NjI1ODg0MSwiZXhwIjoxNjc2NTE4MDQxLCJkYXRhIjp7Im1lbWJlcklkIjo4NDd9fQ.RLX1Jze1PP22oyXTyX0DTXf4xuLqCa_E8SDV1JLwdMI'})
      }
      return Promise.reject(data)
    };
    return data;
  },
  error => {
    return Promise.reject(error);
  },
);

export default request;

<template>
  <div class="addresinp_view">
    <div class="addresinp_view_title">
      <span>申请退换货</span>
    </div>

    <div class="addresinp_view_form">

      <div class="addresinp_view_row gap">
        <div class="inputbox inpwidth">
          <div class="xxdz">退换货原因：</div>
          <selectInp 
            :keydata='keydata'
            placeholder="请选择退换货原因"
            @change = "selChange"
          ></selectInp>
        </div>
      </div>
      
      <div class="addresinp_view_row gap">
        <div class="inputbox">
          <div class="xxdz">上传图片：</div>
          <updatainp ref="updatainp"></updatainp>
        </div>
        
      </div>

      <div class="addresinp_view_row gap">
        <div class="inputbox">
          <div class="xxdz">详情描述：</div>
          <textarea placeholder="点击此处输入详细描述" v-model="returnDesc"></textarea>
        </div>
      </div>

      <div class="addresinp_view_row gap center">
        <button @click="submitfrom">提交申请</button>
      </div>

    </div>

  </div>
</template>
<script>
import selectInp from "@/pages/pc/goodsOrder/common/selectInp.vue"
import updatainp from "@/pages/pc/goodsOrder/common/updatainp.vue"
import { returnGoods } from "@/utils/http.js"

export default {
  data() {
    return {
      keydata:[
        {label:'七天无理由',value:1},
        {label:'商品出现异常',value:2},
        {label:'与商品描述不符',value:3},
        {label:'包裹/收货异常',value:4},
        {label:'其他',value:5},
      ],
      returnReason:'',
      returnDesc:''
    }
  },
  components:{
    selectInp,
    updatainp
  },
  methods: {
    async submitfrom(){
      let param = {
        orderId:this.$route.query.orderId,
        returnReason:this.returnReason,
        returnDesc:this.returnDesc,
        returnImage:this.$refs.updatainp.urlimgArr.join(',')
      }
      let res = await returnGoods(param);
      if(res.status==0){
        this.$message({
          message: '提交成功',
          type: 'success'
        });
      }else{
        this.$message({
          message: '提交失败',
          type: 'warning'
        });
      }
    },
    selChange(str){
      this.returnReason = str.value;
    },
  },
  
}
</script>
<style lang="less" scoped>
.addresinp_view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 1000px;
  min-height: 551px;
  background: #2B2B2B;
  .addresinp_view_title {
    position: relative;
    height: 60px;
    background: #262626;
    line-height: 60px;
    padding: 0px 38px;
    font-size: 20px;
    font-weight: 800;
    color: #FFFFFF;
    &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 28px;
        background-color: #D5A223 ;
      }
  }

  .addresinp_view_form {
    padding: 49px 39px 0px;

    .addresinp_view_row {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 40px;
      .inputbox {
        display: flex;
        justify-content: center;
        align-items: center;
        >div {
          margin-right: 18px;
        }
        >div:last-child {
          margin-right: 0px;
        }
        .xxdz {
          width: 120px;
        }

        textarea {
          width: 795px;
          height: 192px;
          background: #3A3A3A;
          border-radius: 8px;
          border: 1px solid #666666;
          padding: 20px;
          resize:none;
          font-size: 14px;
          color: #fff;
          
          &::placeholder{
            font-size: 16px;
            color: #666666;
          }
          // 滚动条宽度
          &::-webkit-scrollbar {
              width: 6px;
          }
          // 滚动条轨道
          &::-webkit-scrollbar-track {
              background: #ccc;
              border-radius: 2px;
          }
          // 小滑块
          &::-webkit-scrollbar-thumb {
              background: #2B2B2B;
              border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
              background: #2B2B2B;
          }
        }

      }
      .inpwidth {
        width: 450px;
      }

      .addresinfo {
        width: 600px;
        min-height: 214px;
        background: #313131;
        border-radius: 14px;
        padding: 28px 45px 38px;
        .addresinfo_row {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          >div {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-size: 18px;
            font-weight: bold;
            color: #FFFFFF;
            p {
              margin: 5px;
              span {
                font-size: 16px;
                color: #999999;
              }
            }
          }
        }
      }

      .oddNumber {
        div {
          font-size: 18px;
          font-weight: 500;
          color: #FFFFFF;
          margin-bottom: 15px;
        }
        input {
          width: 600px;
          height: 60px;
          background: #3A3A3A;
          border-radius: 8px;
          border: 1px solid #666666;
          font-size: 16px;
          font-weight: 500;
          text-indent: 30px;
          color: #ffffff;
          &::placeholder{
            font-size: 14px;
            font-weight: 500;
            text-indent: 30px;
            color: #666666;
          }
        }
      }

      button {
        width: 180px;
        height: 60px;
        background: #D5A223;
        border-radius: 6px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
      }

      button:hover {
        background: #f3af04;
      }
    }

    .center {
      justify-content: center;
    }

    .gap {
      .inputbox {
        margin-right: 50px;
      }
      .inputbox:last-child {
        margin-right: 0px;
      }

    }
  }
}
  
</style>
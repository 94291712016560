<template>
	<div class="page-container">
		<base-header></base-header>
		<router-view></router-view>
		<base-footer></base-footer>
	</div>
</template>

<script>
	import BaseHeader from "@/components/Header";
	import BaseFooter from "@/components/Footer";

	export default {
		components: {
			BaseFooter,
			BaseHeader
		},
		data() {
			return {

			}
		},
	};
</script>

<style scoped>
	.page-container {
		display: flex;
		flex-direction: column;
	}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addresinp_view" }, [
    _c("div", { staticClass: "backBtn" }, [
      _c(
        "div",
        {
          staticClass: "back",
          on: {
            click: function ($event) {
              return _vm.$router.back()
            },
          },
        },
        [_c("i", { staticClass: "el-icon-back" }), _vm._v("返回上一页\n      ")]
      ),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "addresinp_view_form" }, [
      _c("div", { staticClass: "addresinp_view_row gap" }, [
        _c("div", { staticClass: "inputbox" }, [
          _c("div", [_vm._v("收获人姓名：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.from.contact,
                expression: "from.contact",
                modifiers: { trim: true },
              },
            ],
            attrs: { type: "text", placeholder: "请在此处输入您的姓名(必填)" },
            domProps: { value: _vm.from.contact },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.from, "contact", $event.target.value.trim())
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _c("div", { staticClass: "inputbox" }, [
          _c("div", [_vm._v("收货人电话：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.from.telephone,
                expression: "from.telephone",
                modifiers: { number: true },
              },
            ],
            attrs: { type: "text", placeholder: "请在此处输入您的电话(必填)" },
            domProps: { value: _vm.from.telephone },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.from, "telephone", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "addresinp_view_row gap" }, [
        _c(
          "div",
          { staticClass: "inputbox" },
          [
            _c("div", { staticClass: "xxdz" }, [_vm._v("收获地址：")]),
            _c("selectInp", {
              attrs: {
                placeholder: "省/直辖市/自治区",
                labelname: _vm.provinceName,
                keydata: _vm.regionData,
              },
              on: { change: _vm.changeOption },
            }),
            _c("selectInp", {
              attrs: {
                placeholder: "地级市",
                labelname: _vm.cityName,
                keydata: _vm.cityData,
              },
              on: { change: _vm.changeCity },
            }),
            _c("selectInp", {
              attrs: {
                placeholder: "县/区",
                labelname: _vm.districtName,
                keydata: _vm.districtData,
              },
              on: { change: _vm.changeDistrict },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "addresinp_view_row" }, [
        _c("div", { staticClass: "inputbox" }, [
          _c("div", { staticClass: "xxdz" }, [_vm._v("详细地址：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.from.content,
                expression: "from.content",
              },
            ],
            attrs: { type: "text", placeholder: "点击此处输入详细地址" },
            domProps: { value: _vm.from.content },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.from, "content", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "addresinp_view_row gap" }, [
        _c("button", { on: { click: _vm.setaddAddressd } }, [
          _vm._v(" " + _vm._s(_vm.addressId ? "确认修改" : "确认添加") + " "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addresinp_view_title" }, [
      _c("span", [_vm._v("新增收货地址")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select_inp" }, [
    !_vm.activedata && !_vm.labelname
      ? _c("span", { staticClass: "placeholdertext" }, [
          _vm._v(_vm._s(_vm.placeholder)),
        ])
      : _vm._e(),
    _vm.labelname && !_vm.activedata
      ? _c("span", { staticClass: "dataValue" }, [
          _vm._v(_vm._s(_vm.labelname)),
        ])
      : _vm._e(),
    _vm.activedata
      ? _c("span", { staticClass: "dataValue" }, [
          _vm._v(_vm._s(_vm.activedata.label)),
        ])
      : _vm._e(),
    _c("i"),
    _c("div", { staticClass: "select_list" }, [
      _c(
        "ul",
        _vm._l(_vm.keydata, function (item) {
          return _c(
            "li",
            {
              key: item.value,
              on: {
                click: function ($event) {
                  return _vm.selectOptions(item)
                },
              },
            },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
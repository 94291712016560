<template>
  <div class="order_box">
    <div class="backBtn">
        <div class="back" @click="$router.back()">
          <i class="el-icon-back"></i>返回上一页
        </div>
    </div>

    <div class="address_view">
      <div class="address_view_head">
        <span>收获地址</span>
      </div>

      <div class="address_view_list">
        <addresItem 
          v-for="(item,index) in addresslist" 
          :key="index"
          :dataobj="item"
          :isDefaultAddress = "index==itemindex"
          @changeAddress = "changeAddress(item,index)"
          @changeDefault = "changeDefault"
        ></addresItem>

        <div class="addres_item addaddres" @click="addaddress">
          <div class="add_icon"></div>
          <span>新增收获地址</span>
        </div>
        
      </div>

    </div>

    <div class="goodslist_view">

      <div class="goodslist_table">
        <div class="goodslist_table_header">
          <div>商品名称</div>
          <div>商品属性</div>
          <div>单价</div>
          <div>数量</div>
          <div>金额</div>
        </div>

        <div class="goodslist_table_item">
          <div>
            <div class="goodsimg">
              <img :src="goodsinfo.image" alt="">
            </div>
            <span>{{ goodsinfo.title }}</span>
          </div>
          <div>
            <span>{{ goodsinfo.skuValue }}</span>
          </div>
          <div>
            <span class="Markerstext">￥{{ goodsinfo.price }}</span>
          </div>
          <div>
            <span>{{ goodsinfo.number }}</span>
          </div>
          <div>
            <span class="Markerstext">￥{{ goodsinfo.totalPrice }}</span>
          </div>
        </div>
      </div>

      <div class="goodslist_foot">
        <div>
          <p>快递费用：<span class="Markerstext">￥{{ goodsinfo.courierFee }}</span></p>
          <p>实付款：<span class="Markerstext bigtext">￥{{ goodsinfo.disbursements }}</span></p>
          <button @click="onPay">购买</button>
        </div>
      </div>
    </div>

    <el-dialog title="支付宝扫码" :visible.sync="codeShow" width="30%" @close="onClose">
      <img class="code" :src="codeImg" />
      <div class="tips">支付成功后，请关闭支付窗口</div>
    </el-dialog>

  </div>
</template>
<script>
import QRCode from "qrcode";
import addresItem from "@/pages/pc/goodsOrder/common/addresItem.vue";
import { 
  buyGoods,
  checkPay,
  getAddressList,
  confirmBuyGoods
 } from "@/utils/http.js";

export default {
  data() {
    return {
      addresslist:[],
      itemindex:0,
      codeImg:'',
      codeShow:false,
      timer: null,
      count: "",
      activeAddress:{},
      goodsinfo:{},
      loading:null
    }
  },
  components:{
    addresItem
  },
  created() {
    this.getAddress()
    this.getconfirmBuyGoods()
  },
  methods: {
    addaddress(){
      this.$router.push('/order/addAddress')
    },
    async getAddress(){
      let {data,status} = await getAddressList();
      if(status!=0)return;
      this.addresslist=data.addressList;
      let obj = this.addresslist.filter((item)=>item.isDefault==1);
      this.activeAddress = obj.length!=0?obj[0]:{};
    },
    async getconfirmBuyGoods(){
      let params = {
        goodsId:this.$route.query.goodsId,
        unique:this.$route.query.uniqueid,
        number:parseInt(this.$route.query.number),
      }

      let res = await confirmBuyGoods(params);
      if(res.status!=0)return;
      this.goodsinfo = res.data;
      console.log(this.goodsinfo);

    },
    openFullScreen() {
       this.loading = this.$loading({
          lock: true,
          text: '加载中，请耐心等待...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    onPay(){
      const codeCount = 0;
      let data = { 
        goodsId: this.$route.query.goodsId,
        addressId:this.activeAddress.addressId,
        unique:this.$route.query.uniqueid,
        number:parseInt(this.$route.query.number),
        sid:this.activeAddress.districtId
      };

      this.openFullScreen()
      buyGoods(data).then((res) => {
          this.loading.close();
          if (res.status == 0) {
            this.toCanvas(res.data.html);

            // 将定时器名字赋值到变量中           
            this.count = codeCount;
            this.timer = setInterval(() => {
              this.callback(res.data.outTradeNo);
              if (this.count >= 28800) {
                clearInterval(this.timer);
                this.timer = null;
                this.showWxCode = false;
              } else {
                this.count++;
              }
            }, 3000);            
          }
        })
        .catch((err) => {
          this.loading.close();
          this.$message(err.msg);
        });
    },

    toCanvas(code) {
      QRCode.toDataURL(
        code,
        { errorCorrectionLevel: "L", margin: 2, width: 400 },
        (error, url) => {
          if (error) console.log(error);
          this.codeImg = url;
          this.codeShow = true;
        }
      );
    },

    onClose(){
      this.codeShow = false;
      clearInterval(this.timer);
    },

    callback(outTradeNo) {
      let data = { outTradeNo:outTradeNo };
      checkPay(data).then((res) => {
          this.codeShow = false;
          clearInterval(this.timer);
        })
    },

    changeAddress(item,index){
      this.activeAddress = item;
      this.itemindex = index;
    },

    changeDefault(){
      this.getAddress()
    }
  },
  
}
</script>
<style lang="less" scoped>
.order_box {
  width: 1300px;
  margin: auto;
  overflow: hidden;

  .address_view {
    min-height: 340px;
    margin-top: 10px;
    background-color: #2B2B2B;
    overflow: hidden;

    .address_view_head {
      position: relative;
      margin: 24px 0px;
      padding-left: 38px;
      font-size: 20px;
      font-weight: 800;
      color: #FFFFFF;
      &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 28px;
        background-color: #D5A223 ;
      }
    }

    .address_view_list {
      display: flex;
      flex-wrap: wrap;
      padding: 38px;
      padding-top: 14px;
      .addres_item {
        width: 280px;
        height: 210px;
        margin-right: 34px;
        margin-bottom: 28px;
        background: #383838;
        cursor: pointer;
      }
      .addaddres {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        .add_icon {
          width: 48px;
          height: 48px;
          background: url('~@/assets/goodsOrder/addaddres.png') no-repeat center center;
          background-size: 100% 100%;
        }
        span {
          margin-top: 28px;
        }
      }
      .addres_item:nth-child(4n) {
        margin-right: 0px;
      }
    }


  }

  .goodslist_view {
    margin-top: 25px;
    background-color: #2B2B2B;

    .goodslist_table {
      .goodslist_table_header {
        display: flex;
        align-items: center;
        height: 60px;
        background: #212121;
        >div{
          flex: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          color: #ccc;
        }
        >div:first-child{
          flex: 5;
        }
      }
      .goodslist_table_item {
        display: flex;
        align-items: center;
        height: 140px;
        border-bottom: #ffffff83;
        >div{
          flex: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          color: #fff;

          .goodsimg {
            width: 100px;
            height: 100px;
            margin-right: 31px;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          
        }
        >div:first-child{
          flex: 5;
        }
      }

    }

    .goodslist_foot {
      display: flex;
      justify-content: end;
      padding: 26px 38px;
      text-align: right;
      font-size: 18px;
      font-weight: 400;
      color: #CCCCCC;
      p {
        margin-bottom: 22px;
      }
      
      button {
        width: 120px;
        height: 60px;
        background: #D5A223;
        border-radius: 6px;
        font-size: 26px;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
  }

  /deep/.el-dialog__wrapper {
    .el-dialog {
      width: 500px !important;
      border-radius: 10px;
      .el-dialog__header {
        text-align: center;
        font-weight: 700;
      }
      .el-dialog__body {
        padding: 0;
        padding-bottom: 30px;
      }
    }
    .code {
      width: 500px;
      height: 500px;
    }
    .tips {
      text-align: center;
    }
  }
}
.Markerstext {
  color: #FF0000;
}
.bigtext {
  font-size: 30px;
  font-weight: 800;
} 
.backBtn {
  display: flex;
  align-items: center;
  margin-top: 40px;
  .back {
      width: 150px;
      display: flex;
      align-items: center;
      padding: 10px 0;
      text-align: center;
      font-size: 22px;
      color: #fff;
      margin-bottom: 20px;
      margin-right: 30px;
      cursor: pointer;
      .el-icon-back {
        font-size: 32px;
        color: #fff;
        line-height: 1;
        margin-right: 5px;
      }
    }
}
</style>
<template>
  <div class="container">
    <div class="content">
      <div class="areaBoxs" v-infinite-scroll="loadAreaData"
        infinite-scroll-disabled="areaNoMore">
        <div class="item"
          :class="curItem.templateId == item.templateId ? 'cur' : ''" 
          v-for="(item,index) in interiorLsit"
          :key="'nj'+index" 
          @click="onChoseInterior(item)">
          <div class="txt">内景面积：{{item.covered}}m²</div>
          <div class="price">
            ￥<div class="num">{{item.price}}</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="price">
          价格：<div class="num" v-if="curItem.templateId"><i>￥</i>{{curItem.price}}</div>
        </div>
        <div class="btns" :class="!curItem.templateId ? 'disable' : ''">
          <div class="btn btn-look" @click="onEnter">查看内景</div>
          <div class="btn btn-buy" @click="onPay">购买</div>
        </div>
      </div>
    </div>
    <div class="purchased">
      <div class="title">我的购买</div>
      <div class="list" v-infinite-scroll="loadOrderData"
        infinite-scroll-disabled="orderNoMore">
        <div class="item" v-for="(item,index) in myOrderList" :key="'wdgm'+index">
          <div class="name">{{item.goodsName}}</div>
          <div class="txt">内景面积：{{item.covered}}m²</div>
        </div>
        <el-empty
          description="暂无数据"
          v-if="myOrderList.length == 0"
          style="width:100%;height: 100%;text-align: center"
        ></el-empty>
        <div class="noMore" v-else-if="orderNoMore"> -- 没有更多了-- </div>
      </div>
    </div>
    <el-dialog title="支付宝扫码" :visible.sync="codeShow" width="30%" @close="onClose">
      <img class="code" :src="codeImg" alt="" />
      <div class="tips">支付成功后，请关闭支付窗口</div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { getSid } from "@/utils/auth";
export default {
  data() {
    return {
      areaNoMore: false,
      areaPage: 1,
      orderPage:1,
      orderNoMore:false,
      codeShow: false,
      codeImg: "",
      count: "",
      timer: null,
      interiorLsit:[],
      myOrderList:[],
      curItem:{}
    };
  },
  computed: {
    disabled() {
      return this.noMore;
    },
  },
  created(){
    // this.getInteriorList()
    // this.getMyOrders()
  },
  methods: {
    loadAreaData() {
      this.loading = true;
      setTimeout(() => {
        this.getInteriorList();
        this.loading = false;
        this.areaPage++;
      }, 500);
    },
    getInteriorList() {
      this.$http
        .post(`api/metaverse/land/getInteriorList?page=${this.areaPage}`)
        .then((res) => {
          const { interiorLsit, page } = res.data;
          this.interiorLsit = this.interiorLsit.concat(interiorLsit);
          if (res.data.totalPage < this.areaPage) {
            this.areaNoMore = true;
          }
        }).catch((err) => {
          this.$message(err.msg);
        });;
    },
    loadOrderData(){
      setTimeout(() => {
        this.getMyOrders();
        this.orderPage++;
      }, 500);
    },
    getMyOrders() {
      this.$http
        .post(`api/metaverse/land/getMyOrders?page=${this.orderPage}`)
        .then((res) => {
          const { interiorLsit, page } = res.data;
          this.myOrderList = this.myOrderList.concat(interiorLsit);
          if (res.data.totalPage < this.orderPage) {
            this.orderNoMore = true;
          }
        });
    },
    onChoseInterior(item){
      // console.log(item);
      this.curItem = item;
    },
    onEnter(){
      if (!this.curItem.templateId) return;
      const {templateId}=this.curItem;
      //  console.log({ "templateId": templateId});
      if (window.vuplex) {
        window.vuplex.postMessage({ "templateId": templateId});
      }
    },
    toCanvas(code) {
      QRCode.toDataURL(
        code,
        { errorCorrectionLevel: "L", margin: 2, width: 400 },
        (error, url) => {
          if (error) console.log(error);
          // console.log(url);
          this.codeImg = url;
          this.codeShow = true;
        }
      );
    },
    onPay() {
      // console.log('1');
      const sid = getSid();
      const codeCount = 0;
      let data = { goodsId: this.curItem.goodsId, sid };
      this.$http
        .post(`api/metaverse/goods/buyGoods`, data)
        .then((res) => {
          if (res.status == 0) {
            this.toCanvas(res.data.html);
            // 将定时器名字赋值到变量中           
            this.count = codeCount;
            this.timer = setInterval(() => {
              this.callback(res.data.outTradeNo);
              if (this.count >= 28800) {
                clearInterval(this.timer);
                this.timer = null;
                this.showWxCode = false;
              } else {
                this.count++;
              }
            }, 3000);
          }
        }).catch((err) => {
          this.$message(err.msg);
        });
    },
    callback(outTradeNo) {
      let data = { outTradeNo:outTradeNo };
      this.$http
      .post(`api/metaverse/common/checkPay`, data)
      .then((res) => {
        this.codeShow = false;
        clearInterval(this.timer);
      })       
    },
    onClose(){
      this.codeShow = false;
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="less" scoped>
.container{
  display: flex;
  width: 100%;
  height: 100vh;
  .content{
    position: relative;
    display: flex;
    width: calc(100% - 450px);
  }
  .areaBoxs {
    width: calc(100% - 0px);
    height: calc(100% - 200px);
    padding: 5px 10px 5px 25px;
    margin:40px 24px;
    overflow-y: scroll;
    z-index: 1;
    .noMore{
      flex: 0 0 100%;
      height: 20px;
      color: #ccc;
      text-align: center;
    }
    .item{
      flex:0 0 calc(50% - 40px);
      width: calc(50% - 40px);
      height: 150px;
      display: flex;
      float: left;
      justify-content: space-between;
      align-items: center;
      height: 150px;
      padding: 25px 30px;
      margin:0 40px 30px 0;
      background: url(https://ipqncdn1.artuns.com/344bc29ccce11bee851310e044b334ca_158@2x.png)
        no-repeat center;
      background-size: cover;
      border-radius: 14px;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;
      &.cur {
        background-image: url(https://ipqncdn1.artuns.com/98417f76cf38cd7aa46784cc1ab30df5_159@2x.png);
      }
      .txt {
        font-size: 28px;
        font-weight: 800;
        color: #ffffff;
      }
      .price {
        display: flex;
        align-items: baseline;
        font-size: 20px;
        font-weight: bold;
        color: #0436b5;
        .num {
          font-size: 40px;
        }
      }
    }
  }
  .areaBoxs::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .areaBoxs::-webkit-scrollbar-track {
    // background-color: transparent;
    background-color: #4B4B4B;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  .areaBoxs::-webkit-scrollbar-thumb {
    background-color: #D5A223;
    // background: transparent;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  .footer{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 120px;
    background: linear-gradient(180deg, #333C58 0%, #1B2731 100%);
    padding:0 45px;
    .price{
      display: flex;
      align-items: center;
      font-size: 26px;
      font-weight: 700;
      color: #fff;
      .num{
        display: flex;
        font-size: 40px;
        color: #D6A324;
        i{
          font-size: 20px;
          font-style: initial;
          margin-top: 11px;
        }
      }
    }
    .btns{
      display: flex;
      &.disable {
        .btn{
          background: #958787 !important;
          cursor: default !important;
        }
      }
      .btn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        height: 60px;
        line-height: 60px;
        background: linear-gradient(109deg, #00CBFF 0%, #07BDE6 60%, #0BB3D6 100%);
        border-radius: 30px;
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        cursor: pointer;
        &.btn-buy{
          margin-left: 30px;
          background: linear-gradient(109deg, #D5A223 0%, #FFD263 100%);
        }
      }
    }
  }
  .purchased{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 100vh;
    background: #333C58;
    .title{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding:30px 25px 25px 30px;
      font-size: 30px;
      color: #fff;
      font-weight: 700;
      background: #333C58;
    }
    .list{
      width: 100%;
      height: calc(100vh - 100px);
      padding:0 30px 30px;
      display: flex;
      flex-direction: column;
      margin: 100px 0 30px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        // background-color: transparent;
        background-color: rgba(192, 169, 22, 0.1);
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(147, 147, 153, 0.1);
        // background: transparent;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
      .noMore{
        height: 20px !important;
        color: #ccc;
        text-align: center;
      }
      .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 120px;
        padding: 40px 28px;
        margin-bottom: 20px;
        color: #fff;
        background: url(https://ipqncdn1.artuns.com/yj/1683876909211_purchased_bg.png)
        no-repeat center;
        background-size: cover;
        border-radius: 14px;
        
        .name{
          font-size: 28px;
          font-weight: 700;
        }
        .txt{
          font-size: 20px;
        }
      }
    }
  }
  /deep/.el-dialog__wrapper {
    .el-dialog {
      width: 500px !important;
      border-radius: 10px;
      .el-dialog__header {
        text-align: center;
        font-weight: 700;
      }
      .el-dialog__body {
        padding: 0;
        padding-bottom: 30px;
      }
    }
    .code {
      width: 500px;
      height: 500px;
    }
    .tips {
      text-align: center;
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goods-container" },
    [
      _c("div", { staticClass: "backBtn" }, [
        _c(
          "div",
          {
            staticClass: "back",
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [_c("i", { staticClass: "el-icon-back" }), _vm._v("返回上一页\n    ")]
        ),
        _c(
          "div",
          {
            staticClass: "backHome back",
            on: {
              click: function ($event) {
                return _vm.onRoute(-1)
              },
            },
          },
          [_c("i", { staticClass: "el-icon-back" }), _vm._v("返回首页\n    ")]
        ),
      ]),
      _c("div", { staticClass: "goods" }, [
        _c("img", {
          staticClass: "img",
          attrs: { src: _vm.goodsInfo.image, alt: "" },
        }),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.goodsInfo.title)),
          ]),
          _c("div", { staticClass: "block-num" }, [
            _c("div", { staticClass: "leave" }, [
              _c("div", { staticClass: "txt" }, [_vm._v("剩余")]),
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.goodsInfo.number - _vm.goodsInfo.sellNumber) +
                    "份\n          "
                ),
              ]),
            ]),
            _c("div", { staticClass: "total" }, [
              _vm._v("发售总量：" + _vm._s(_vm.goodsInfo.number) + "份"),
            ]),
          ]),
          _c("div", { staticClass: "date" }, [
            _c("div", { staticClass: "txt" }, [
              _vm._v(
                "发行日期：" +
                  _vm._s(_vm._f("formatDate")(_vm.goodsInfo.sellTime))
              ),
            ]),
            _c("div", { staticClass: "txt" }, [
              _vm._v("发行商： " + _vm._s(_vm.goodsInfo.companyName)),
            ]),
          ]),
          _c("div", { staticClass: "code" }, [
            _vm._v(
              "\n        哈希值：" +
                _vm._s(_vm.goodsInfo.chainHash) +
                "\n      "
            ),
          ]),
          _c("div", { staticClass: "happy-hour" }, [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "title" }, [_vm._v("售价")]),
              _c("div", { staticClass: "price-footer" }, [
                _c("div", { staticClass: "price-new" }, [
                  _c("i", [_vm._v("￥")]),
                  _vm._v(_vm._s(_vm.goodsInfo.price)),
                ]),
                _vm.goodsInfo.isDiscount
                  ? _c("div", { staticClass: "price-old" }, [
                      _c("div", { staticClass: "discount" }, [
                        _vm._v(_vm._s(_vm.goodsInfo.discountValueStr)),
                      ]),
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("￥" + _vm._s(_vm.goodsInfo.originalPrice)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm.goodsInfo.isDiscount
              ? _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("优惠时间")]),
                  _c(
                    "div",
                    { staticClass: "time" },
                    [
                      _c("djs", {
                        staticClass: "pay-time",
                        attrs: { djsType: "deadTime", endTime: _vm.time },
                        on: {
                          timeUp: function ($event) {
                            return _vm.callback()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "block-buy" }, [
            _c(
              "div",
              {
                staticClass: "btn",
                class: !_vm.agreeChecked ? "disable" : "",
                on: { click: _vm.onPay },
              },
              [_vm._v("\n          购买\n        ")]
            ),
            _c("div", { staticClass: "agree" }, [
              _c(
                "div",
                { staticClass: "checkbox-agree" },
                [
                  _c("el-checkbox", {
                    on: { change: _vm.onAgreeChange },
                    model: {
                      value: _vm.agreeChecked,
                      callback: function ($$v) {
                        _vm.agreeChecked = $$v
                      },
                      expression: "agreeChecked",
                    },
                  }),
                ],
                1
              ),
              _vm._v("\n          已经同意\n          "),
              _c(
                "div",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      _vm.diaologShow = true
                    },
                  },
                },
                [_vm._v("\n            《艺集虚拟商品用户协定》\n          ")]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "details" }, [
          _c("div", { staticClass: "title" }, [_vm._v("商品详情")]),
          _c("div", {
            staticClass: "txt",
            domProps: { innerHTML: _vm._s(_vm.goodsInfo.content) },
          }),
        ]),
        _c("div", { staticClass: "recommend" }, [
          _c("div", { staticClass: "title" }, [_vm._v("推荐商品")]),
          _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.goodsList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.onRoute(item)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: item.image, alt: item.title },
                  }),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("div", { staticClass: "goods-footer" }, [
                      _c("div", { staticClass: "price-new" }, [
                        _c("i", [_vm._v("￥")]),
                        _vm._v(_vm._s(item.price)),
                      ]),
                      item.isDiscount
                        ? _c("div", { staticClass: "price-old" }, [
                            _vm._v(
                              "\n                ￥" +
                                _vm._s(item.originalPrice) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.companyName)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "《艺集虚拟商品用户协定》",
            visible: _vm.diaologShow,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.diaologShow = $event
            },
          },
        },
        [
          _c("span", [_vm._v("这是一段协定")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.diaologShow = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "支付宝扫码", visible: _vm.codeShow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.codeShow = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c("img", {
            staticClass: "code",
            attrs: { src: _vm.codeImg, alt: "" },
          }),
          _c("div", { staticClass: "tips" }, [
            _vm._v("支付成功后，请关闭支付窗口"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./area.vue?vue&type=template&id=1f61bdfc&scoped=true"
import script from "./area.vue?vue&type=script&lang=js"
export * from "./area.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f61bdfc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\公司项目\\银山\\元宇宙\\MetaverseMall\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f61bdfc')) {
      api.createRecord('1f61bdfc', component.options)
    } else {
      api.reload('1f61bdfc', component.options)
    }
    module.hot.accept("./area.vue?vue&type=template&id=1f61bdfc&scoped=true", function () {
      api.rerender('1f61bdfc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/pc/auth/area.vue"
export default component.exports
<template>
  <div class="my-container">
    <div class="w1300">
      <div class="layout-wrap">
        <div class="user-box">
          <img class="head-img" :src="userInfo.memberHead" alt="">
          <div class="name">{{userInfo.nickname}}</div>
          <div class="num">ID:8562155621</div>
        </div>
        <div class="menu-tabs">
          <router-link 
            tag="span"
            class="item"
            v-for="(item,index) in menuList" 
            :key="index"  
            :to="{ path:item.path }">
						{{item.label}}
            <i class="el-icon-arrow-right"></i>
					</router-link>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
  
<script>
import { removeToken,getToken } from '@/utils/auth';
export default {
  data() {
    return {
      menuList:[{
        label:'我的藏品',
        path:'/my/myCollection'
      },{
        label:'个人资料',
        path:'/my/personInfo'
      },{
        label:'我的订单',
        path:'/my/order'
      }]

      
    };
  },
  created(){
		this.hasToken = getToken();
  },
	computed: {
		userInfo(){
			let userInfo=this.$store.state.userInfo;
			if(userInfo.memberHead){
				return userInfo
			}else{
				return JSON.parse(localStorage.getItem('userInfo'));
			}
		}
	},
  methods: {

  },
};
</script>

<style lang='less' scoped>
.my-container{
  padding: 60px 0 50px;
  background: #161730;
  .w1300{
    display: flex;
    width: 1300px;
    margin: 0 auto;
  }
}
.layout-wrap{
  margin-right: 40px;
  .user-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 296px;
    margin-bottom: 50px;
    background: linear-gradient(264deg, #56174A 0%, #272E6B 100%);
    border-radius: 13px;
    .head-img{
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin-bottom: 28px;
    }
    .name{
      margin-bottom: 24px;
      font-size: 22px;
      font-weight: 700;
      color: #fff;
    }
    .num{
      font-size: 16px;
      color: #999;
    }
  }
  .menu-tabs{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 240px;
    background: #00000030;
    border-radius: 13px;
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      padding: 0 30px;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      border-bottom: 1px solid #ffffff10;
      cursor: pointer;
      &.router-link-active{
        color: #EC5C56;
      }
      &:last-child{
        border-color:transparent ;
      }
      .el-icon-arrow-right{
        font-size: 18px;
        opacity: .6;
      }
    }
  }
}
</style>

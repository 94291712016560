var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-container" }, [
    _c(
      "div",
      { staticClass: "w1300" },
      [
        _c("div", { staticClass: "layout-wrap" }, [
          _c("div", { staticClass: "user-box" }, [
            _c("img", {
              staticClass: "head-img",
              attrs: { src: _vm.userInfo.memberHead, alt: "" },
            }),
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.userInfo.nickname)),
            ]),
            _c("div", { staticClass: "num" }, [_vm._v("ID:8562155621")]),
          ]),
          _c(
            "div",
            { staticClass: "menu-tabs" },
            _vm._l(_vm.menuList, function (item, index) {
              return _c(
                "router-link",
                {
                  key: index,
                  staticClass: "item",
                  attrs: { tag: "span", to: { path: item.path } },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t" + _vm._s(item.label) + "\n            "
                  ),
                  _c("i", { staticClass: "el-icon-arrow-right" }),
                ]
              )
            }),
            1
          ),
        ]),
        _c("router-view"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import { isMobile } from "@/utils/index";

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['Login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start() 
  const { meta = {} } = to
  if (!meta.doNotSetTitle && meta.title) {
    document.title = meta.title
  }
  if(whiteList.indexOf(to.name) == -1){
    next()
  }else{
    next()
  }
    
  // if(isMobile){
  //   if(to.path.indexOf('h5') == -1){
  //     next('/h5/home')
  //   }else{
  //     next()
  //   }
  // }else{
  //   if(to.path.indexOf('h5')>-1){
  //     next('/')
  //   }else{
  //     next()
  //   }
  // }
  NProgress.done()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderinfo_box" }, [
    _c("div", { staticClass: "orderinfo_status" }, [
      _c("div", { staticClass: "orderinfo_status_icon" }),
      _c("p", { staticClass: "orderinfo_status_text" }, [
        _vm._v("订单状态：" + _vm._s(_vm.statustext[_vm.orderStatus])),
      ]),
      _c("p", [_vm._v("订单编号：" + _vm._s(_vm.goodsinfo.orderSn))]),
      _c("p", [_vm._v("收货地址：" + _vm._s(_vm.goodsinfo.address))]),
    ]),
    _c("div", { staticClass: "orderinfo_list_view" }, [
      _c("div", { staticClass: "orderinfo_list_number" }, [
        _vm._v("韵达快递 运单号:432691873134624"),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "orderinfo_list_table" }, [
        _c("div", [
          _c("div", { staticClass: "goodimg" }, [
            _c("img", { attrs: { src: _vm.goodsinfo.image } }),
          ]),
          _c("div", { staticClass: "goodimgtext" }, [
            _c("p", [_vm._v(_vm._s(_vm.goodsinfo.title))]),
          ]),
        ]),
        _c("div", [_c("span", [_vm._v(_vm._s(_vm.goodsinfo.skuValue))])]),
        _c("div", [
          _c("span", { staticClass: "redtext" }, [
            _vm._v("￥" + _vm._s(_vm.goodsinfo.price)),
          ]),
        ]),
        _c("div", [_vm._v(_vm._s(_vm.goodsinfo.goodsNumber))]),
        _c("div", [
          _c("span", { staticClass: "redtext" }, [
            _vm._v("￥" + _vm._s(_vm.goodsinfo.totalPrice)),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "orderinfo_list_foot" }, [
      _c("div", [
        _c("p", [
          _vm._v("快递费用："),
          _c("span", { staticClass: "redtext" }, [
            _vm._v("￥" + _vm._s(_vm.goodsinfo.courierFee)),
          ]),
        ]),
        _c("p", [
          _vm._v("实付款："),
          _c("span", { staticClass: "bigredtext" }, [
            _vm._v("￥" + _vm._s(_vm.goodsinfo.orderPayment)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "orderinfo_list_table tbhead" }, [
      _c("div", [_vm._v("商品名称")]),
      _c("div", [_vm._v("商品属性")]),
      _c("div", [_vm._v("单价")]),
      _c("div", [_vm._v("数量")]),
      _c("div", [_vm._v("金额")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="select_inp">
      <span v-if="!activedata&&!labelname" class="placeholdertext">{{ placeholder }}</span>
      <span v-if="labelname&&!activedata" class="dataValue">{{ labelname }}</span>
      <span v-if="activedata" class="dataValue">{{ activedata.label }}</span>
      <i></i>
      <div class="select_list">
        <ul>
          <li 
            v-for="item in keydata" 
            :key="item.value"
            @click="selectOptions(item)"
          >{{ item.label }}</li>
        </ul>
      </div>
  </div>
</template>
<script>
export default {
  props:{
    placeholder:{
      default:''
    },
    labelname:{
      default:''
    },
    keydata:{
      type:Array,
    }
  },
  data() {
    return {
      activedata:null
    }
  },
  methods: {
    selectOptions(item){
      this.activedata=item;
      this.$emit('change',item)
    }
  },
  watch:{
    keydata:function(){
      this.activedata=null;
    }
  }
  
}
</script>
<style lang="less" scoped>
.select_inp {
  position: relative;
  flex: 1;
  height: 60px;
  background: #3A3A3A;
  border-radius: 8px;
  border: 1px solid #666666;
  text-indent: 20px;
  line-height: 60px;
  cursor: pointer;

  .placeholdertext {
    color: #666666;
    font-size: 16px;
  }

  .dataValue {
    color: #ffffff;
    font-size: 16px;
  }

  i{
    position: absolute;
    top: 45%;
    right: 20px;
    transform-origin: center center;
    width: 15px;
    height: 8px;
    background: url('~@/assets/goodsOrder/Select_icon.png') no-repeat center center;
    background-size: 100% 100%;
  }

  .select_list {
    display: none;
    position: absolute;
    left: 0px;
    top: 100%;
    width: 100%;
    height: 225px;
    border: 1px solid #666666;
    background-color: #3A3A3A;
    overflow-y: auto;
    z-index: 99;
    ul {
      padding: 0px;
      margin: 0px;

      li {
        height: 45px;
        line-height: 45px;
      }

      li:hover {
        background: #2B2B2B;
      }


    }

    // 滚动条宽度
    &::-webkit-scrollbar {
        width: 6px;
    }
    // 滚动条轨道
    &::-webkit-scrollbar-track {
        background: #ccc;
        border-radius: 2px;
    }
    // 小滑块
    &::-webkit-scrollbar-thumb {
        background: #2B2B2B;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #2B2B2B;
    }
  }

  &:hover i{
    transform: rotate(180deg);
  }
  &:hover .select_list{
    display: block;
  }
}
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "specification" }, [
    _c("div", { staticClass: "specification_name" }, [
      _vm._v(_vm._s(_vm.item.specName) + "："),
    ]),
    _c(
      "div",
      { staticClass: "specification_list" },
      _vm._l(_vm.item.lists, function (itmes, index) {
        return _c(
          "div",
          {
            key: index,
            class: _vm.optionalindex == index ? "specification_listactive" : "",
            on: {
              click: function ($event) {
                return _vm.optionalSize(itmes, index)
              },
            },
          },
          [_vm._v(_vm._s(itmes.itemName))]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
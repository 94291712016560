import Vue from 'vue'
import Vuex from 'vuex'
import request from '@/utils/request'
import { setToken } from '@/utils/auth';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    userInfo: JSON.parse(localStorage.getItem('userInfo')),
    breadcrumbList :[],
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_STATE_KEY(state, { key, data }){
      state[key] = data
    },
    SET_USER_INFO(state, data){
      state.userInfo = data
      localStorage.setItem('userInfo', JSON.stringify(data))
    },
    SET_CRUMB_LIST(state, data){
      state.breadcrumbList = data
    },
    
  },
  getters: {
    loading: state => state.loading, 
    userInfo: state => state.userInfo,  
  },
  actions: {
    GetUploadToken() {
      return request.get('/api/common/getQiniuToken')
    },
    GetWxJSSDK({ commit }, data) {
      return request.post('/api/share/getWechatJsSdk', data)
    },
  },
  modules: {
  }
})

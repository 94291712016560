<template>
  <div class="updatainp_view">
    <div class="fileimg" v-for="(item,index) in imgArr" :key="index">
      <img :src="item">
      <i @click="deleteimg(index)"></i>
    </div>

    <label for="updataiid">
      <div class="fileicon">  
        <i></i>
      </div>
    </label>
    <input type="file" @change="fileChange" id="updataiid" ref="updataRef" accept="image/*">
  </div>
</template>
<script>
import {qnRequest} from "@/utils/http.js";
import config from "@/utils/config.js"

export default {
  data() {
    return {
      file:null,
      imgArr:[],
      urlimgArr:[]
    }
  },
  methods: {

    fileChange(){
      let that = this;
      let f = this.$refs.updataRef.files[0];

      const params = new FormData();
      params.append("token", localStorage.getItem('qnToken') );
      params.append("file", f);
      params.append("key", `yyzsc/${new Date().getTime()}_${f.name}`);
      qnRequest(params).then(res=>{
        this.urlimgArr.push(config.qnurl + res.key)
      })

      let reads= new FileReader();
      reads.readAsDataURL(f);
      reads.onload=function () {
        that.imgArr.push(this.result)
        that.$refs.updataRef.value=''
      };

    },

    deleteimg(i){
      console.log(i);
      this.imgArr=this.imgArr.filter((item,index)=>{
        return index!=i
      })
    }

  },
  
}
</script>
<style lang="less" scoped>
.updatainp_view {
  display: flex;
  flex-wrap: wrap;
  .fileicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    background: #3A3A3A;
    border-radius: 8px;
    border: 1px solid #666666;
    cursor: pointer;
    i{
      width: 45px;
      height: 45px;
      background: url('~@/assets/goodsOrder/addaddres.png') no-repeat center center;
      background-size: 100% 100%;
    }
  }

  .fileimg {
    position: relative;
    width: 160px;
    height: 160px;
    background: #3A3A3A;
    border-radius: 8px;
    border: 1px solid #666666;
    margin-right: 22px;
    margin-bottom: 22px;
    img {
      width: 100%;
      height: 100%;
    }
    i{
      position: absolute;
      top: 0px;
      right: 0px;
      width: 25px;
      height: 25px;
      background: url('~@/assets/goodsOrder/deletimg.png') no-repeat center center;
      background-size: 100% 100%;
      cursor: pointer;

    }

  }

  input {
    display: none;
  }

}
  
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "wrap-left" },
      [
        _vm._l(_vm.leftTabs, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item",
              class: _vm.activeTab == index ? "active" : "",
              on: {
                click: function ($event) {
                  return _vm.onTab(index)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(item) + "\n    ")]
          )
        }),
        _c(
          "div",
          { staticClass: "user_center", on: { click: _vm.gosusercenter } },
          [_vm._v("\n      个人中心\n    ")]
        ),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "wrap-right" },
      [
        _c("div", { staticClass: "head-banner" }, [
          _c("div", { staticClass: "swiper swiper-container" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.banner, function (item, index) {
                return _c(
                  "div",
                  { key: "b_" + index, staticClass: "swiper-slide item" },
                  [
                    _c("div", { staticClass: "imgs" }, [
                      _c("img", { attrs: { src: item, alt: "" } }),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "swiper-pagination" }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "tabs" },
          _vm._l(_vm.types, function (item, index) {
            return _c(
              "div",
              {
                key: "t_" + index,
                staticClass: "tab",
                class: _vm.activetype == index ? "active" : "",
                on: {
                  click: function ($event) {
                    return _vm.onType(index)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(item) + "\n      ")]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "list" },
          _vm._l(_vm.goodsList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item",
                on: {
                  click: function ($event) {
                    return _vm.onRoute(item)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: item.image, alt: "" },
                }),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("div", { staticClass: "goods-footer" }, [
                    _c("div", { staticClass: "price-new" }, [
                      _c("i", [_vm._v("￥")]),
                      _vm._v(_vm._s(item.price)),
                    ]),
                    item.isDiscount
                      ? _c("div", { staticClass: "price-old" }, [
                          _vm._v(
                            "\n              ￥" +
                              _vm._s(item.originalPrice) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.companyName)),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
        _vm.totalPage && _vm.goodsList.length
          ? _c(
              "div",
              { staticClass: "paging" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.page,
                    layout: "prev, pager, next",
                    "page-count": _vm.totalPage,
                  },
                  on: { "current-change": _vm.getGoodsListsBy },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.goodsList.length
          ? _c("el-empty", { attrs: { description: "暂无数据 ╥﹏╥..." } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div></div>
</template>

<script>
import { setToken, setSid } from "@/utils/auth";
export default {
  data() {
    return {
      token: "",
    };
  },
  created() {
    if (this.$route.query.token) {
      setToken(this.$route.query.token);
      this.$router.push({ 
        path: `/studentArea?companyId=${this.$route.query.companyId}`
      });
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="container">
    <div class="tabs">
      <div class="item" 
      :class="session == item ? 'cur' : ''"  
      v-for="(item,index) in sessionList" 
      :key="index"
      @click="onChooseSession(item)">{{item}}级</div>
    </div>
    <div class="list" v-infinite-scroll="loadData"
        infinite-scroll-disabled="disabled">
      <div class="item" 
        :class="curItem.memberId == item.memberId ? 'cur' : ''" 
        v-for="(item,index) in landList" 
        :key="index" 
        @click="onChoseDoorplate(index, item)">
        <div class="name">{{item.name}}</div>
        <div class="realName">真实姓名：{{item.realName}}</div>
      </div>
      <el-empty
        description="暂无数据"
        v-if="landList.length == 0"
        style="width:100%;height: 100%;text-align: center"
      ></el-empty>
      <div class="noMore" v-else-if="noMore"> -- 没有更多了-- </div>
    </div>
    <div class="footer">
      <div class="choose">
        已选中：<div class="num" v-if="curItem && curItem.name">{{curItem.name}}（{{curItem.realName}}）</div>
      </div>
      <div class="btns">
        <div class="btn btn-look" @click="onjoinScence">进入</div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data () {
    return {
      page:1,
      companyId:'',
      curItem: {},
      session:'',
      loading: false,
      noMore: false,
      sessionList:[],
      landList:[]
    }
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created(){
    this.getSession();
    this.companyId=this.$route.query.companyId;
  },
  methods:{
    // /api/metaverse/land/getStudentLand?page=1
    loadData() {
      this.loading = true;
      setTimeout(() => {
        this.getStudentLand();
        this.loading = false;
        this.page++;
      }, 500);
    },
    getSession(){
      this.$http
        .post(`api/metaverse/land/getSession`)
        .then((res) => {
          this.sessionList=res.data;
          this.session=res.data[0];
          // this.getStudentLand()
        });
    },
    getStudentLand(){
      const data={
        page:this.page,
        session:this.session,
        companyId:this.companyId,
      }
      this.$http
        .post(`api/metaverse/land/getStudentLand`,data)
        .then((res) => {
          const { landList, page } = res.data;
          this.landList = this.landList.concat(landList);
          if (res.data.totalPage < this.page) {
            this.noMore = true;
          }
          if (page == 2) {
            this.curItem = this.landList[0];
          }
        });
    },
    onChoseDoorplate(index, item) {
      this.curItem = item;
    },
    onChooseSession(item){
      this.session = item;
      this.page=1;
      this.landList=[]
      this.getStudentLand()
    },
    onjoinScence(){
      if (!this.curItem.insideId) return;
      const {insideId,templateId}=this.curItem;
      if (window.vuplex) {
        window.vuplex.postMessage({ "insideId": insideId,"templateId": templateId });
      }
    },
  },
  beforeDestroy() {
    this.landList = []; //清空数组
  },
}

</script>

<style lang='less' scoped>
.container{
  height: 100vh;
  .tabs{
    display: flex;
    border-bottom: 1px solid #ffffff20;
    .item{
      min-width: 180px;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      color: #ccc;
      line-height: 80px;
      cursor: pointer;
      &.cur {
        position: relative;
        color: #D6A324;
        &::after{
          content: '';
          position: absolute;
          bottom: 0;
          left: calc(50% - 50px);
          width: 100px;
          height: 2px;
          background: #D6A324;
        }
      }
    }
  }
  .list{
    padding:40px 45px;
    margin:0 20px 0 0;
    height: calc(100% - 200px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      // background-color: transparent;
      background-color: #4B4B4B;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #D5A223;
      // background: transparent;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
    .noMore{
      float: left;
      width: 100%;
      height: 20px;
      color: #ccc;
      text-align: center;
    }
    .item{
      float: left;
      width:calc(25% - 30px);
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px;
      margin:0 40px 30px 0;
      background: url(https://ipqncdn1.artuns.com/344bc29ccce11bee851310e044b334ca_158@2x.png)
        no-repeat center;
      background-size: cover;
      border-radius: 14px;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;
      &.cur {
        background-image: url(https://ipqncdn1.artuns.com/98417f76cf38cd7aa46784cc1ab30df5_159@2x.png);
      }
      &:nth-child(4n){
        margin-right: 0;
      }
      .name{
        margin-bottom: 16px;
        font-size: 30px;
        font-weight: 700;
        color: #fff;
      }
      .realName{
       
        font-size: 24px;
        font-weight: 700;
        color: #1752F8;
      }
    }
  }
  .footer{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 120px;
    background: linear-gradient(180deg, #333C58 0%, #1B2731 100%);
    padding:0 45px;
    .choose{
      display: flex;
      align-items: center;
      font-size: 30px;
      font-weight: 700;
      color: #fff;
      .num{
        display: flex;
        font-size: 30px;
        color: #D6A324;
      }
    }
    .btns{
      display: flex;
      .btn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        min-width: 160px;
        height: 60px;
        margin-left: auto;
        background: linear-gradient(109deg, #D5A223 0%, #FFD263 100%);
        border-radius: 30px;
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}
</style>

import Empty from '@/layouts/empty.vue'
import personLayout from '@/layouts/person.vue'
import PcLayouts from '@/layouts/index.vue'

export default {
  path: '/',
  name: 'Pc',
  redirect: '/shops',
  component: PcLayouts,
  children: [{
    path: 'shops',
    component: Empty,
    children: [{
      path: '',
      name: 'shopsList',
      meta: {
        title: '系列藏品'
      },
      component: () => import ( /* webpackChunkName: "ShopsList" */ '@/pages/pc/shops/index.vue')
    },{
      path: 'detail',
      name: 'shopsDetail',
      meta: {
        title: '系列藏品详情'
      },
      component: () => import ( /* webpackChunkName: "ShopsDetail" */ '@/pages/pc/shops/detail.vue')
    },{
      path: 'physicalGoodsInfo',
      name: 'physicalGoodsInfo',
      meta: {
        title: '实物藏品详情'
      },
      component: () => import ( /* webpackChunkName: "ShopsDetail" */ '@/pages/pc/shops/physicalGoodsInfo.vue')
    }]
  },{
    path: 'auth',
    component: Empty,
    children: [{
      path: '',
      name: 'authInitial',
      meta: {
        title: '登录授权'
      },
      component: () => import ( /* webpackChunkName: "AuthInitial" */ '@/pages/pc/auth/initial.vue')
    },{
      path: 'goods',
      name: 'authGoodsInitial',
      meta: {
        title: '登录授权'
      },
      component: () => import ( /* webpackChunkName: "AuthGoodsInitial" */ '@/pages/pc/auth/goods.vue')
    },{
      path: 'area',
      meta: {
        title: '登录授权'
      },
      component: () => import ( /* webpackChunkName: "AuthAreaInitial" */ '@/pages/pc/auth/area.vue')
    },{
      path: 'studentArea',
      meta: {
        title: '登录授权'
      },
      component: () => import ( /* webpackChunkName: "AuthStudentArea" */ '@/pages/pc/auth/studentArea.vue')
    },{
      path: 'interior',
      meta: {
        title: '登录授权'
      },
      component: () => import ( /* webpackChunkName: "AuthInterior" */ '@/pages/pc/auth/interior.vue')
    }]
  },{
    path: 'area',
    component: Empty,
    children: [{
      path: '',
      name: 'areaIndex',
      meta: {
        title: '土地售卖'
      },
      component: () => import ( /* webpackChunkName: "AreaIndex" */ '@/pages/pc/area/index.vue')
    }]
  },{
    path: 'interior',
    component: Empty,
    children: [{
      path: '',
      name: 'interiorIndex',
      meta: {
        title: '土地售卖'
      },
      component: () => import ( /* webpackChunkName: "InteriorIndex" */ '@/pages/pc/interior/index.vue')
    }]
  },{
    path: 'studentArea',
    component: Empty,
    children: [{
      path: '',
      name: 'studentAreaIndex',
      meta: {
        title: '学生土地'
      },
      component: () => import ( /* webpackChunkName: "StudentArea" */ '@/pages/pc/studentArea/index.vue')
    }]
  },{
    path: 'order',
    component: Empty,
    children: [{
      path: '',
      name: 'orderIndex',
      meta: {
        title: '订单'
      },
      component: () => import (  '@/pages/pc/goodsOrder/index.vue')
    },{
      path: 'addAddress',
      name: 'addAddress',
      meta: {
        title: '新增收获地址'
      },
      component: () => import (  '@/pages/pc/goodsOrder/addAddress.vue')
    },{
      path: 'userCentre',
      name: 'userCentre',
      meta: {
        title: '个人中心'
      },
      component: () => import (  '@/pages/pc/goodsOrder/userCentre.vue'),
      redirect:'userCentre/orderList',
      children:[{
        path: 'orderList',
        name: 'orderList',
        meta: {
          title: '订单管理'
        },
        component: () => import (  '@/pages/pc/goodsOrder/orderlist.vue')
      },{
        path: 'orderInfo',
        name: 'orderInfo',
        meta: {
          title: '订单详情'
        },
        component: () => import (  '@/pages/pc/goodsOrder/orderInfo.vue')
      },{
        path: 'addressList',
        name:'addressList',
        meta: {
          title: '地址管理'
        },
        component: () => import (  '@/pages/pc/goodsOrder/addressList.vue')
      }],
    },{
      path: 'returnGoods',
      name: 'returnGoods',
      meta: {
        title: '申请退换货'
      },
      component: () => import (  '@/pages/pc/goodsOrder/returnGoods.vue')
    },{
      path: 'expressDelivery',
      name: 'expressDelivery',
      meta: {
        title: '快递单号'
      },
      component: () => import (  '@/pages/pc/goodsOrder/expressDelivery.vue')
    }]
  },{
    path: 'showGoods',
    name: 'showGoods',
    meta: {
      title: '商品'
    },
    component: () => import ('@/pages/pc/auth/showGoods.vue')
  }
   
]
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notfound-container" },
    [
      _vm._v("\n  当前页面走丢啦~~\n  "),
      _c(
        "router-link",
        { attrs: { to: "/" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": ".5rem" },
              attrs: { type: "primary", block: "" },
            },
            [_vm._v("回首页")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="footer">
	</div>
</template>
	
<script>
export default {
data () {
	return {
	}
},
methods:{
 
}
}

</script>

<style lang='less' scoped>

</style>

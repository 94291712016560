<template>
  <div></div>
</template>

<script>
import { setToken, setSid } from "@/utils/auth";
export default {
  data() {
    return {
      token: "",
    };
  },
  created() {
    if (this.$route.query.token) {
      setToken(this.$route.query.token);
      this.$router.push({ 
        path: `/shops/detail?goodId=${this.$route.query.goodId}&dressId=${this.$route.query.dressId}` 
      });
    }
    // console.log(this.$route.query.token);
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>

<template>
    <div class="goods-container">
  
      <div class="goods">
        <img :src="goodsInfo.image" alt="" class="img" />
        <div class="info">
          <div class="name">{{ goodsInfo.title }}</div>
          <div class="block-num">
            <div class="leave">
              <div class="txt">剩余</div>
              <div class="num">
                {{ activeSku?(activeSku.number):'' }}份
              </div>
            </div>
            <div class="total">发售总量：{{ activeSku?activeSku.number+activeSku.sellNumber:''  }}份</div>
          </div>

          <specificationItem 
            v-for="(item,index) in goodsInfo.specLists" 
            :key="index"
            :item="item"
            @speciChange="speciChange($event,index)"
          ></specificationItem>
  
          <div class="counterbox">
            <div class="counterbox_name">数量：</div>
            <div class="counterbox_num">
              <div @click="changeCountnum(-1)">-</div>
              <div>{{ countnum }}</div>
              <div @click="changeCountnum(1)">+</div>
            </div>
          </div>
  
          <div class="happy-hour">
            <div class="box">
              <div class="title">售价</div>
  
              <div class="price-footer" v-if="activeSku">
                <div class="price-new"><i>￥</i>{{ activeSku.price }}</div>

                <div class="price-old" v-show="goodsInfo.isDiscount">
                  <div class="discount">{{ activeSku.discountValueStr }}</div>
                  <div class="txt">￥{{ activeSku.originalPrice }}</div>
                </div>
              </div>
  
            </div>
            <div class="box" v-if="goodsInfo.isDiscount">
              <div class="title">优惠时间</div>
              <div class="time">
                <span>{{ djsdata }}</span>
              </div>
            </div>
          </div>
          <div class="block-buy">
            <div
              class="btn"
              :class="!agreeChecked ? 'disable' : ''"
              @click="onPay"
            >
              购买
            </div>
            <div class="agree">
              <div class="checkbox-agree">
                <el-checkbox
                  v-model="agreeChecked"
                ></el-checkbox>
              </div>
              已经同意
              <div class="link" @click="diaologShow = true">
                《艺集虚拟商品用户协定》
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="details">
          <div class="title">商品详情</div>
          <div class="txt" v-html="goodsInfo.content"></div>
        </div>
        <div class="recommend">
          <div class="title">推荐商品</div>
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in goodsList"
              :key="index"
              @click="onRoute(item)"
            >
              <img :src="item.image" :alt="item.title" class="img" />
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="goods-footer">
                  <div class="price-new"><i>￥</i>{{ item.price }}</div>
                  <div class="price-old" v-if="item.isDiscount">
                    ￥{{ item.originalPrice }}
                  </div>
                  <div class="name">{{ item.companyName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        title="《艺集虚拟商品用户协定》"
        :visible.sync="diaologShow"
        width="30%"
      >
        <span>这是一段协定</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="diaologShow = false">确 定</el-button>
        </span>
      </el-dialog>
  
    </div>
  </template>
  
  <script>
  import { formatDate } from "@/utils/timeStamp.js";
  import { 
    getGoodsDetails,
    doLogin,
 } from "@/utils/http.js"
  import specificationItem from "@/pages/pc/shops/components/specificationItem"
  import { getToken , setToken } from '@/utils/auth' // get token from cookie

  export default {
    components: { specificationItem },
    // 过滤时间
    filters: {
      formatDate(time) {
        time = time * 1000;
        let date = new Date(time);
        return formatDate(date, "yyyy-MM-dd");
      },
      toNum(percent) {
        var num = (percent.replace("%", "") / 100) * 10;
        parseFloat(num * 10).toFixed(2);
        return num;
      },
    },
    data() {
      return {
        codeShow: false,
        codeImg: "",
        goodId: 0,
        time: 0,
        count: "",
        countnum:0,
        timer: null,
        agreeChecked: true,
        diaologShow: false,
        goodsInfo: {},
        goodsList: [], //推荐商品
        skutypeArr:[],
        activeSku:null,
        countdowntime:null,
        isoldtoken:true,
      };
    },
    watch: {
      $route(res) {
        this.goodId = res.query.goodId;
        this.dressId = res.query.dressId;
        this.getGoodsDetails();
      },
    },
    created() {
      this.goodId = this.$route.query.goodId;
      this.dressId = this.$route.query.dressId;
     
      if(!getToken()){
            let data = {
                mobile:'19999999996',
                verCode:'8888'
            }
            doLogin(data).then((res)=>{
                if(res.status!=0)return;
                setToken(res.data.token);
                this.getGoodsDetails();
                this.getGoodsListsBySort();
            })
        }else{
            this.getGoodsDetails();
            this.getGoodsListsBySort();
        }
    },
    computed:{
        djsdata(){
            if(this.time==0) return '已结束';
            let d = parseInt(this.time / 60 / 60 / 24);
            let h = parseInt(this.time / 60 / 60 % 24);
            let m = parseInt(this.time / 60 % 60);
            let s = parseInt(this.time % 60);
            h = h < 10 ? '0' + h : h;
            m = m < 10 ? '0' + m : m;
            s = s < 10 ? '0' + s : s;
            return d+'D:'+h+'h:'+m+'m:'+s+'s'
        }
    },
    methods: {
      getGoodsDetails() {
        getGoodsDetails({dressId:this.goodId}).then((res) => {
            const { goodsInfo } = res.data;
            this.goodsInfo = goodsInfo;
            this.time = goodsInfo.sellTime - (Date.now()/1000).toFixed(0);
            this.time = this.time<=0?0:this.time;
            this.countdown()

            this.skutypeArr = this.goodsInfo.specLists.map(()=>{
                return null;
            })

            this.defaultSku()
          });
      },

      getGoodsListsBySort() {
        this.$http.post("api/metaverse/goods/getGoodsListsBySort").then((res) => {
          const { goodsList } = res.data;
          this.goodsList = goodsList.filter(item=>item.type==3);
        });
      },

      countdown(){
        if(this.time<=0)return;
        this.countdowntime = setInterval(()=>{
            this.time-=1;
        },1000)
      },

     
     
      onPay() {
        if (!this.agreeChecked||!this.activeSku) return;
        if (this.countnum==0) return this.$message({message: '请选择商品数量',type: 'warning'});

        let query = {
            goodsId:this.goodsInfo.goodsId,
            uniqueid:this.activeSku.unique,
            number:this.countnum
        }
        this.$router.push({path:'/order',query})
      },

      onRoute(item) {
        if(item.type==3){
            this.$router.push(`/showGoods?goodId=${item.dressId}`);
        }else if(item==-1){
            // 返回首页
            this.$router.push(`/shops`);
        }else{
            this.$router.push(`/shops/detail?goodId=${item.goodsId}`);
        }
      },
      
      changeCountnum(i) {
        if(!this.activeSku)return this.$message({message: '请选好规格',type: 'warning'});
        if(this.countnum==0&&i<0)return;
        if(this.countnum+i>(this.activeSku.number))return;
        this.countnum+=i
      },

      speciChange (item,index){
        this.skutypeArr[index]=item.sku;

        if(this.skutypeArr.every(item=> item)){
            this.activeSku=this.goodsInfo.skuLists[this.skutypeArr.join(',')];
        }
      },

      defaultSku(){
        this.goodsInfo.specLists.forEach((item,index) => {
          this.skutypeArr[index] = item.lists[0].sku;
        });

        if(this.skutypeArr.every(item=> item)){
            this.activeSku=this.goodsInfo.skuLists[this.skutypeArr.join(',')];
        }
      }
  
    },
  };
  </script>
  
  <style lang="less" scoped>
  .goods-container {
    background: linear-gradient(180deg, #333c58 0%, #10161b 100%);
    width: 100%;
    padding: 40px 100px;
    .backBtn {
      display: flex;
      align-items: center;
    }
    .back {
      width: 150px;
      display: flex;
      align-items: center;
      padding: 10px 0;
      text-align: center;
      font-size: 22px;
      color: #fff;
      margin-bottom: 20px;
      margin-right: 30px;
      cursor: pointer;
      .el-icon-back {
        font-size: 32px;
        color: #fff;
        line-height: 1;
        margin-right: 5px;
      }
    }
    .backHome {
      width: 130px;
    }
    /deep/.el-dialog__wrapper {
      .el-dialog {
        width: 500px !important;
        border-radius: 10px;
        .el-dialog__header {
          text-align: center;
          font-weight: 700;
        }
        .el-dialog__body {
          padding: 0;
          padding-bottom: 30px;
        }
      }
      .code {
        width: 500px;
        height: 500px;
      }
      .tips {
        text-align: center;
      }
    }
    .goods {
      display: flex;
      .img {
        width: 700px;
        height: 460px;
        margin-right: 60px;
      }
      .info {
        flex: 1;
        .name {
          margin-bottom: 20px;
          font-size: 28px;
          font-weight: 700;
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .block-num {
          display: flex;
          justify-content: space-between;
          margin: 28px 0 14px;
          font-size: 18px;
          .leave {
            display: flex;
            align-items: center;
            height: 40px;
            background: #0d1323;
            border-radius: 6px;
            color: #fff;
            line-height: 40px;
            overflow: hidden;
            .txt {
              width: 70px;
              height: 40px;
              text-align: center;
              background: linear-gradient(to right, #efbe40, #c49623);
              
            }
            .num {
              width: 100px;
              text-align: center;
            }
          }
          .total {
            color: #999;
          }
        }
        
        .counterbox {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-bottom: 13px;
          
          .counterbox_name {
            font-size: 16px;
            font-weight: 800;
            color: #FFFFFF;
          }
          .counterbox_num {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 181px;
            height: 50px;
            border-radius: 9px;
            border: 1px solid #3B4871;
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
            >div {
              flex: 1;
              height: 100%;
              line-height: 50px;
              text-align: center;
              border-right: 1px solid #3B4871;
            }
            >div:last-child {
              border-right: 0px;
            }
            >div:nth-child(1),
            >div:nth-child(3) {
              font-size: 36px;
              color: #D8A931;
              cursor: pointer;
              user-select: none;
            }
  
            >div:nth-child(1):active,
            >div:nth-child(3):active {
              font-size: 46px;
            }
          }
        }
        .date {
          display: flex;
          align-items: center;
          color: #ccc;
          font-size: 18px;
          line-height: 60px;
          .txt {
            margin-right: 20px;
          }
        }
        .code {
          margin-bottom: 18px;
          color: #ccc;
          font-size: 18px;
          line-height: 50px;
        }
        .happy-hour {
          border-top: 1px solid #444444;
          display: flex;
          .box {
            padding: 30px 0 30px 30px;
            // flex: 1;
            .title {
              margin-bottom: 30px;
              color: #fff;
              font-size: 18px;
            }
            .price-footer {
              display: flex;
              align-items: flex-end;
              .price-new {
                margin-right: 18px;
                font-size: 36px;
                line-height: 1;
                color: #ff0000;
                font-weight: 700;
                i {
                  font-size: 18px;
                  font-style: initial;
                }
              }
              .price-old {
                display: flex;
                align-items: flex-end;
                .txt {
                  color: #959595;
                  font-size: 14px;
                  text-decoration: line-through;
                }
              }
              .discount {
                height: 30px;
                margin-right: 10px;
                line-height: 30px;
                border-radius: 6px;
                text-align: center;
                padding: 0 10px;
                font-size: 18px;
                color: #fff;
                font-weight: 700;
                background: linear-gradient(to right, #efbe40, #c49623);
              }
            }
            .time {
              display: flex;
              align-items: center;
              letter-spacing: 2px;
              font-size: 28px;
              color: #fff;
              font-weight: 700;
            }
          }
        }
        .block-buy {
          display: flex;
          align-items: center;
          .btn {
            width: 120px;
            height: 60px;
            margin-right: 60px;
            line-height: 60px;
            text-align: center;
            background: #d5a223;
            border-radius: 6px;
            color: #fff;
            font-size: 26px;
            font-weight: 700;
            cursor: pointer;
            &.disable {
              background: #888;
              color: #bbb;
              cursor: no-drop;
            }
          }
          .agree {
            display: flex;
            font-size: 18px;
            color: #fff;
            ::v-deep.checkbox-agree {
              width: 26px;
              height: 26px;
              margin-right: 10px;
              .el-checkbox {
                .el-checkbox__inner {
                  width: 26px;
                  height: 26px;
                  background: #191919;
                  border: none;
                  &::after {
                    width: 5px;
                    height: 10px;
                    left: 10px;
                    top: 6px;
                    border-right: 2px solid #ff0000;
                    border-bottom: 2px solid #ff0000;
                    transform: translate(-50%, -50%) scale(0);
                    transition: transform 0.15s ease-in;
                  }
                }
                &.is-checked {
                  .el-checkbox__inner {
                    &::after {
                      transform: rotate(45deg);
                    }
                  }
                }
              }
              input[type="checkbox"] {
                position: relative;
                display: inline-block;
                appearance: none;
                -webkit-appearance: none;
                width: 26px;
                height: 26px;
                outline: none;
                background-color: #191919;
                margin: 0;
                cursor: pointer;
                &:checked {
                  &::after {
                    border-right: 2px solid #ff0000;
                    border-bottom: 2px solid #ff0000;
                    transform: rotate(45deg);
                    display: block;
                  }
                }
                &::after {
                  display: inline-block;
                  content: "";
                  width: 5px;
                  height: 10px;
                  position: absolute;
                  left: 10px;
                  top: 6px;
                  transform: translate(-50%, -50%) scale(0);
                  transition: transform 0.15s ease-in;
                }
              }
            }
            .link {
              color: #ff0000;
              text-decoration: none;
            }
          }
        }
      }
    }
    .content {
      display: flex;
      padding-top: 45px;
      .details {
        flex: 1;
        display: flex;
        flex-direction: column;
        .title {
          margin-bottom: 25px;
          font-size: 24px;
          font-weight: 700;
          color: #fff;
        }
        .txt {
          font-size: 16px;
          color: #ccc !important;
          line-height: 32px;
          img {
            width: 100%;
            margin: 30px 0;
          }
        }
      }
      .recommend {
        display: flex;
        flex-direction: column;
        margin-left: 60px;
        .title {
          margin-bottom: 25px;
          font-size: 24px;
          font-weight: 700;
          color: #fff;
        }
        .list {
          display: flex;
          flex-direction: column;
          .item {
            display: flex;
            flex-direction: column;
            color: #fff;
            text-decoration: none;
            .img {
              width: 390px;
              height: 260px;
              margin-bottom: 10px;
              cursor: pointer;
            }
            .info {
              padding: 10px 0 30px;
              .title {
                margin-bottom: 6px;
                font-size: 18px;
                font-weight: 700;
              }
            }
            .goods-footer {
              display: flex;
              align-items: center;
              width: 100%;
              .price-new {
                margin-right: 10px;
                font-size: 20px;
                color: #ff0000;
                font-weight: 700;
                i {
                  font-size: 12px;
                  font-style: initial;
                }
              }
              .price-old {
                color: #959595;
                font-size: 14px;
                text-decoration: line-through;
              }
              .name {
                font-size: 14px;
                color: #d5a223;
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }
  </style>
  